import {
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
    writeBatch
} from 'firebase/firestore';
import { firebaseApp } from './firebase';
import {
    getAuth,
    deleteUser as deleteFirebaseUser,
    onAuthStateChanged
} from 'firebase/auth';
import useStore from 'components/sharedCode/stores/store';
import { initDashboardLogic } from 'components/platformFiles/webSpecific/initDashboard';
import { showToast } from 'components/sharedCode/Utils/showToastWrapper';
import { userDataSubUpdate } from 'components/platformFiles/stores/userActions';
import { v4 as uuidv4 } from 'uuid';
import { setStorageValue } from 'components/platformFiles/utils/localStorage';
import {
    migrateToV3Call,
    syncCategoriesCall
} from 'components/platformFiles/cloudFunctionCaller';

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export function getUser(uid) {
    return getDoc(doc(db, 'users', uid)).then(format);
}
export const getUserData = async (uid) => {
    const docRef = doc(db, 'users', uid);
    let docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
};
// Update an existing user
export function updateUser(uid, data) {
    return updateDoc(doc(db, 'users', uid), data);
}
// Create a new user

export const deleteUser = async (uid) => {
    // try {
    //     const userDocRef = doc(db, 'users', uid);
    //     const itemsCollectionRef = collection(db, 'items');
    //     const sharedCollectionRef = collection(db, 'shared');
    //     const batch = writeBatch(db);
    //     const itemsQuery = query(itemsCollectionRef, where('owner', '==', uid));
    //     const sharedQuery = query(sharedCollectionRef, where('owner', '==', uid));
    //     const itemsQuerySnapshot = await getDocs(itemsQuery);
    //     itemsQuerySnapshot.forEach((doc) => {
    //         const itemData = doc.data();
    //         // Check if item has the "roles" property and its length is greater than 1
    //         if (itemData.roles && itemData.roles.length > 1) {
    //             return; // Skip to the next item
    //         }
    //         batch.delete(doc.ref);
    //     });
    //     const sharedQuerySnapshot = await getDocs(sharedQuery);
    //     sharedQuerySnapshot.forEach((doc) => {
    //         batch.delete(doc.ref);
    //     });
    //     // Delete the user document
    //     batch.delete(userDocRef);
    //     // Commit the batch
    //     await batch.commit();
    //     // Delete the Firebase Authentication user
    //     if (auth.currentUser) {
    //         await deleteFirebaseUser(auth.currentUser);
    //     }
    //     console.log('All necessary documents have been deleted.');
    //     return true;
    // } catch (error) {
    //     console.error('Error deleting user: ', error);
    //     throw error;
    // }
};
// export const deleteUser = async (uid) => {
//     try {
//         const userCollectionRef = firestore.collection('users');
//         const itemsCollectionRef = firestore.collection('items');
//         const sharedCollectionRef = firestore.collection('shared');

//         const batch = firestore.batch();
//         const userDocRef = userCollectionRef.doc(uid);

//         const itemsQuery = itemsCollectionRef.where('owner', '==', uid);
//         const sharedQuery = sharedCollectionRef.where('owner', '==', uid);

//         const itemsQuerySnapshot = await itemsQuery.get();
//         itemsQuerySnapshot.forEach((itemDoc) => {
//             const itemData = itemDoc.data();

//             // Check if item has the "roles" property and its length is greater than 1
//             if (itemData.roles && itemData.roles.length > 1) {
//                 return; // Skip to the next item
//             }

//             const itemDocRef = itemsCollectionRef.doc(itemDoc.id);
//             batch.delete(itemDocRef);
//         });

//         const sharedQuerySnapshot = await sharedQuery.get();
//         sharedQuerySnapshot.forEach((itemDoc) => {
//             const itemDocRef = sharedCollectionRef.doc(itemDoc.id);
//             batch.delete(itemDocRef);
//         });

//         batch.delete(userDocRef);

//         await batch.commit();

//         await firebase.auth().currentUser.delete();

//         console.log('All necessary documents have been deleted.');
//         return true;
//     } catch (error) {
//         console.error('Error deleting user: ', error);
//         throw error;
//     }
// };

function format(response) {
    // Converts doc into object that contains data and `doc.id`
    const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
    if (response.docs) {
        // Handle a collection of docs
        return response.docs.map(formatDoc);
    } else {
        // Handle a single doc
        return response.exists() ? formatDoc(response) : null;
    }
}

export const subscribeUserDoc = async (userId) => {
    let collectionName = 'users';
    let id = collectionName + '_' + userId;
    let initial = true;
    let allSubscriptions = useStore.getState().allSubscriptions;
    if (allSubscriptions[id]) {
        allSubscriptions[id]();
        delete allSubscriptions[id];
        useStore.setState({ allSubscriptions: allSubscriptions });
    }
    //unsub -> setupListener
    let unsubscribe = onSnapshot(
        doc(db, collectionName, userId),
        async (doc) => {
            if (initial) {
                let data = null;
                if (doc.exists()) {
                    data = doc.data();
                    if (data.version !== 3) {
                        let promise = setStorageValue('localWSRef', null);
                        const res = await migrateToV3Call();
                        await promise;

                        if (res.data.success) {
                            data = res.data.userData;
                        } else {
                            showToast(
                                'Error loading data',
                                'Error migrate',
                                'error'
                            );
                            return;
                        }
                    }
                    initDashboardLogic(data);
                }
                initial = false;
            } else {
                if (doc.exists()) {
                    let data = doc.data();
                    userDataSubUpdate(data);
                }
            }
        },
        (error) => {
            showToast('Error loading data', error.message, 'error');
        }
    );
    useStore.getState().addSubscription(id, unsubscribe);
};

export const subscribeDoc = async (docId, collectionName) => {
    let id = collectionName + '_' + docId;
    let allSubscriptions = useStore.getState().allSubscriptions;

    if (allSubscriptions[id]) {
        allSubscriptions[id]();
        delete allSubscriptions[id];
        useStore.setState({ allSubscriptions: allSubscriptions });
    }

    let unsubscribe = onSnapshot(
        doc(db, collectionName, docId),
        (doc) => {
            if (doc.exists()) {
                let data: any = doc.data();
                if (collectionName === 'items') {
                    useStore.getState().updateWsState(data);
                }
            } else {
                console.log(
                    'error fetching doc Document does not exist',
                    docId,
                    collectionName
                );

                showToast(
                    'Something went wrong',
                    'Workspace does not exist',
                    'error'
                );
            }
        },
        (error) => {
            console.log('error fetching doc', docId, collectionName);
            var errorMessage = error.message;
            showToast('Something went wrong', errorMessage, 'error');
        }
    );
    useStore.getState().addSubscription(id, unsubscribe);
};
export const getUserIdDoc = async (collection) => {
    return new Promise((resolve, reject) => {
        let unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                unsubscribe();
                const docRef = doc(db, collection, user.uid);
                try {
                    let docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        resolve(docSnap.data());
                    } else {
                        reject(new Error('Document does not exist'));
                    }
                } catch (error) {
                    reject(error);
                }
            } else {
                unsubscribe();
                reject(new Error('No user signed in'));
            }
        });
    });
};

export const setDocInCol = async (data, collection, setOwner = false) => {
    return new Promise((resolve, reject) => {
        let unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                let userId = user.uid;
                unsubscribe();
                let currentTimestamp = new Date();
                setDoc(doc(db, collection, userId), {
                    ...data,
                    lastUser: userId,
                    lastUpdated: currentTimestamp,
                    ...(setOwner && { owner: userId })
                })
                    .then((res) => {
                        resolve({ success: true });
                    })
                    .catch((error) => {
                        reject(error);
                        console.error('Error updating document: ', error);
                        var errorCode = error.code;
                        var errorMessage = error.message;
                    });
            } else {
                reject('no user found');
                unsubscribe();
            }
        });
    });
};

export const clearRemindersUpdate = async (payload) => {
    const {
        wsTabData,
        wsId,
        reminderData,
        remindersToClear,
        userId,
        workspaces
    } = payload;
    const batch = writeBatch(db);

    try {
        const filteredRemindersData = reminderData.filter(
            (reminder) => !remindersToClear.includes(reminder.id)
        );

        const userReminderDocRef = doc(db, 'reminders', userId);
        batch.update(userReminderDocRef, { reminders: filteredRemindersData });

        const updateTabsHasAlarm = (tabs) => {
            let tabIdsUpdated = [];
            tabs.forEach((tab) => {
                if (
                    remindersToClear.includes(tab.id) &&
                    tab.hasAlarm &&
                    tab.hasAlarm.includes(userId)
                ) {
                    tabIdsUpdated.push(tab.id);
                    const index = tab.hasAlarm.indexOf(userId);
                    tab.hasAlarm.splice(index, 1);
                }
            });
            return tabIdsUpdated;
        };

        let allTabIdsUpdated = [];
        wsTabData.forEach((group) => {
            let tabIdsUpdatedInGroup = updateTabsHasAlarm(group.tabs);
            allTabIdsUpdated.push(...tabIdsUpdatedInGroup);
        });

        if (allTabIdsUpdated.length !== 0) {
            const workspaceDocRef = doc(db, 'items', wsId);
            batch.update(workspaceDocRef, {
                tabData: wsTabData,
                updateId: uuidv4()
            });
        }

        let tabsLeft = remindersToClear.filter(
            (reminder) => !allTabIdsUpdated.includes(reminder)
        );

        if (tabsLeft.length !== 0) {
            let wsIds = [];
            reminderData.forEach((reminder) => {
                if (
                    tabsLeft.includes(reminder.id) &&
                    !wsIds.includes(reminder.content.WSid)
                ) {
                    wsIds.push(reminder.content.WSid);
                }
            });

            let tabsLeftUnknown = await processTabsLeft(
                wsIds,
                tabsLeft,
                userId,
                batch
            );

            if (tabsLeftUnknown.length !== 0) {
                let wsIdsLeftToCheck = [];
                workspaces.forEach((workspace) => {
                    if (
                        !wsIds.includes(workspace.id) &&
                        workspace.id !== wsId
                    ) {
                        wsIdsLeftToCheck.push(workspace.id);
                    }
                });

                await processTabsLeft(
                    wsIdsLeftToCheck,
                    tabsLeftUnknown,
                    userId,
                    batch
                );
            }
        }

        await batch.commit();
        return {
            msg: 'success',
            message: 'Reminders and workspaces updated successfully.'
        };
    } catch (error) {
        throw new Error(
            `Error updating reminders and workspaces: ${error.toString()}`
        );
    }
};

const processTabsLeft = async (wsIds, tabsLeft, userId, batch) => {
    const docsFetchPromises = wsIds.map((wsid) => {
        return getDoc(doc(db, 'items', wsid))
            .then((docSnapshot) => {
                return docSnapshot; // Return the document snapshot if successful
            })
            .catch((error) => {
                console.error(
                    `Failed to fetch document with ID ${wsid}: ${error}`
                );
                return null; // Return null or any other appropriate value on error
            });
    });

    const docsSnapshots = await Promise.all(docsFetchPromises);

    let updatedTabIds = [];

    for (const docSnapshot of docsSnapshots) {
        if (docSnapshot === null) {
            continue;
        }
        if (!docSnapshot.exists()) continue;

        const docData = docSnapshot.data();
        const tabData = docData.tabData;

        let docUpdated = false;
        tabData.forEach((tabGroup) => {
            tabGroup.tabs.forEach((tab) => {
                if (
                    tabsLeft.includes(tab.id) &&
                    tab.hasAlarm &&
                    tab.hasAlarm.includes(userId)
                ) {
                    const userIndex = tab.hasAlarm.indexOf(userId);
                    tab.hasAlarm.splice(userIndex, 1);
                    updatedTabIds.push(tab.id);
                    docUpdated = true;
                }
            });
        });

        if (docUpdated) {
            batch.update(doc(db, 'items', docSnapshot.id), {
                tabData,
                updateId: uuidv4()
            });
        }
    }
    let tabsLeftUnfound = tabsLeft.filter(
        (tab) => !updatedTabIds.includes(tab)
    );

    return tabsLeftUnfound;
};

export const changeWorkspaceDb = (newWsId) => {
    unsubscribeFromCollection('items');
    subscribeDoc(newWsId, 'items');
    setStorageValue('localWSRef', newWsId);
};

function unsubscribeFromCollection(collectionName) {
    const allSubscriptions = useStore.getState().allSubscriptions;
    Object.keys(allSubscriptions).forEach((key) => {
        if (key.startsWith(collectionName + '_')) {
            allSubscriptions[key]();
            delete allSubscriptions[key];
        }
    });
    useStore.setState({ allSubscriptions: allSubscriptions });
}

export const createWorkspaceDb = async () => {
    return new Promise((resolve, reject) => {
        let newId = uuidv4();
        let unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                let userId = user.uid;
                unsubscribe(); // Unsubscribe to auth changes after getting the user

                try {
                    const userDocRef = doc(db, 'users', userId);
                    const userSnapshot = await getDoc(userDocRef);

                    if (!userSnapshot.exists()) {
                        throw new Error('User does not exist');
                    }

                    let userData = userSnapshot.data();

                    let workspaces = userData.workspaces
                        ? userData.workspaces
                        : [];
                    let newWSTitle = `Workspace ${workspaces.length + 1}`;
                    let catData = [
                        { id: uuidv4(), name: 'Untitled', tabIndex: 0 }
                    ];

                    let updatedWorkspaces = [
                        ...workspaces,
                        {
                            name: newWSTitle,
                            catLength: 1,
                            id: newId,
                            emoji: '📦',
                            catData: catData
                        }
                    ];

                    // Prepare the batch
                    const batch = writeBatch(db);

                    // Update the user document with new workspace information
                    batch.set(
                        userDocRef,
                        { workspaces: updatedWorkspaces, updateId: newId },
                        { merge: true }
                    );

                    // Prepare the new item document
                    const itemDocRef = doc(db, 'items', newId);
                    batch.set(
                        itemDocRef,
                        {
                            owner: userId,
                            lastTypeUpdated: 'initData',
                            lastUpdated: serverTimestamp(),
                            categories: catData,
                            userInfo: { itemsSaved: 0 },
                            tabData: [],
                            workspace: false,
                            WSid: newId,
                            updateId: newId
                        },
                        { merge: true }
                    );

                    await batch.commit();

                    changeWorkspaceDb(newId);
                    resolve({
                        success: true,
                        data: {
                            ...userData,
                            workspaces: updatedWorkspaces,
                            updateId: newId
                        }
                    });
                } catch (error) {
                    console.log('Error updating workspace: ', error);
                    resolve({ error: error.message });
                }
            } else {
                unsubscribe();
                resolve({ error: 'No user logged in' });
                console.log('No user logged in');
            }
        });
    });
};

export const setUserDoc = async (data) => {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userId = user.uid;
                const userDocRef = doc(db, 'users', userId);
                const currentTimestamp = new Date();

                try {
                    await setDoc(
                        userDocRef,
                        {
                            ...data,
                            lastUser: userId,
                            lastUpdated: currentTimestamp
                        },
                        { merge: true }
                    );

                    resolve('User data updated successfully');
                } catch (error) {
                    // sendErrorToFront(error.message, error.code, errorNumber);
                    reject(error);
                } finally {
                    unsubscribe();
                }
            } else {
                const errorMessage = 'No user id in background';
                // sendErrorToFront(errorMessage, 555, 555);
                unsubscribe();
                reject(new Error(errorMessage));
            }
        });
    });
};

export const setDocumentSimple = async (
    uid,
    collection,
    data,
    merge = true
) => {
    return await setDoc(doc(db, collection, uid), data, { merge: merge });
};

export const deleteDeletedItemsDataByIdUpdate = async (idsToDelete) => {
    try {
        let deletedItemsData: any = await getUserIdDoc('deletedItems');
        let filteredDeletedItems = deletedItemsData.deletedItems.filter(
            (item) => !idsToDelete.includes(item.deletedData.id)
        );
        setDocInCol({ deletedItems: filteredDeletedItems }, 'deletedItems');
    } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
    }
};

export const updateCategoriesDb = async (newWsData, currentWsId) => {
    try {
        const itemsDataDocRef = doc(db, 'items', currentWsId);
        await updateDoc(itemsDataDocRef, newWsData);
        let res = await syncCategoriesCall({
            wsId1: currentWsId,
            wsId2: false
        });
        return {
            msg: 'success'
        };
    } catch (e) {
        return {
            msg: 'error',
            errorMsg: e
        };
    }
};

export const moveGroupDataDb = async (payload) => {
    const { groupToMoveId, toWsId, toCatId, currentTabData, currentWsId } =
        payload;
    const batch = writeBatch(db);
    try {
        if (toWsId === currentWsId) {
            // Update the categoryID for the group inside currentTabData
            const updatedTabData = currentTabData.map((group) => {
                if (group.id === groupToMoveId) {
                    return { ...group, categoryID: toCatId };
                }
                return group;
            });
            const currentDocRef = doc(db, 'items', currentWsId);
            await updateDoc(currentDocRef, {
                tabData: updatedTabData,
                updateId: uuidv4()
            });
            return { msg: 'success' };
        } else {
            // Fetch the target workspace document
            const toWsDocRef = doc(db, 'items', toWsId);
            const toWsDocSnap = await getDoc(toWsDocRef);
            // Find the group to move and update its categoryID
            const groupToMove = currentTabData.find(
                (group) => group.id === groupToMoveId
            );
            const updatedGroupToMove = { ...groupToMove, categoryID: toCatId };

            if (!toWsDocSnap.exists()) {
                return {
                    msg: 'error',
                    error: 'Document does not exist!'
                };

                return;
            }

            const currentDocRef = doc(db, 'items', currentWsId);
            batch.update(currentDocRef, {
                tabData: arrayRemove(groupToMove),
                updateId: uuidv4()
            });
            batch.update(toWsDocRef, {
                tabData: arrayUnion(updatedGroupToMove),
                updateId: uuidv4()
            });

            // Commit the batch
            await batch.commit();
            return { msg: 'success' };
        }
    } catch (e) {
        return { msg: 'error', error: e.message };
    }
};

export const moveTabBetweenGroupsDb = async (payload) => {
    const { tabIds, toWsId, toGroupId, currentWsId, currentTabData } = payload;
    const batch = writeBatch(db);
    let tabsToMove = [];

    try {
        // Locate and remove the tab from its current group
        const updatedCurrentTabData = currentTabData.map((group) => {
            return {
                ...group,
                tabs: group.tabs.filter((tab) => {
                    if (tabIds.includes(tab.id)) {
                        tabsToMove.push(tab); // Capture the tab to move
                        return false; // Remove tab from current location
                    }
                    return true; // Keep other tabs
                })
            };
        });
        if (tabsToMove.length !== tabIds.length) {
            console.log('Tab not found');
            return { msg: 'error', error: 'Tab not found' };
        }

        if (toWsId === currentWsId) {
            // If moving within the same workspace, find the target group and add the tab
            const targetGroupIndex = updatedCurrentTabData.findIndex(
                (group) => group.id === toGroupId
            );
            if (targetGroupIndex === -1) {
                console.log('Target group not found in the current workspace');

                return {
                    msg: 'error',
                    error: 'Target group not found in the current workspace'
                };
            }
            updatedCurrentTabData[targetGroupIndex].tabs.push(...tabsToMove);
            const currentDocRef = doc(db, 'items', currentWsId);
            await updateDoc(currentDocRef, {
                tabData: updatedCurrentTabData,
                updateId: uuidv4()
            });
            return { msg: 'success' };
        } else {
            // Moving to a different workspace
            // Update the current workspace document
            const currentDocRef = doc(db, 'items', currentWsId);
            batch.update(currentDocRef, {
                tabData: updatedCurrentTabData,
                updateId: uuidv4()
            });

            // Fetch the target workspace document to add the tab
            const toWsDocRef = doc(db, 'items', toWsId);
            const toWsDocSnap = await getDoc(toWsDocRef);
            if (!toWsDocSnap.exists()) {
                console.log('Target workspace document does not exist!');
                return {
                    msg: 'error',
                    error: 'Target workspace document does not exist!'
                };
            }

            let toWsTabData = toWsDocSnap.data().tabData || [];
            // Find the target group in the new workspace to add the tab
            const targetGroupIndex = toWsTabData.findIndex(
                (group) => group.id === toGroupId
            );
            if (targetGroupIndex !== -1) {
                toWsTabData[targetGroupIndex].tabs = [
                    ...toWsTabData[targetGroupIndex].tabs,
                    ...tabsToMove
                ];
            } else {
                console.log('Target group not found in the target workspace');
                return {
                    msg: 'error',
                    error: 'Target group not found in the target workspace'
                };
            }

            batch.update(toWsDocRef, {
                tabData: toWsTabData,
                updateId: uuidv4()
            });

            // Commit the batch
            await batch.commit();
            return { msg: 'success' };
        }
    } catch (e) {
        return { msg: 'error', error: e.message };
    }
};

export const getDocWithError = async (collection, ref) => {
    return new Promise(async (resolve, reject) => {
        const docRef = doc(db, collection, ref);
        try {
            let docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                resolve(docSnap.data());
            } else {
                reject(new Error('Document does not exist'));
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const moveCategoryDb = async (payload) => {
    const { originalWsId, newWsId, catIdToMove, newIndex } = payload;
    const batch = writeBatch(db);
    const originalWsRef = doc(db, 'items', originalWsId);
    const newWsRef = doc(db, 'items', newWsId);

    try {
        let originalWsData, newWsData;

        if (originalWsId === newWsId) {
            // If the IDs are the same, fetch once
            const wsDoc = await getDoc(originalWsRef);
            originalWsData = newWsData = wsDoc.data();
        } else {
            // If the IDs are different, fetch in parallel
            const [originalWsDoc, newWsDoc] = await Promise.all([
                getDoc(originalWsRef),
                getDoc(newWsRef)
            ]);
            originalWsData = originalWsDoc.data();
            newWsData = newWsDoc.data();
        }

        const isSameWorkspace = originalWsId === newWsId;
        const movedCategory = originalWsData.categories.find(
            (cat) => cat.id === catIdToMove
        );
        if (!movedCategory) {
            console.error('Category to move not found');
            return { msg: 'error', error: 'Category to move not found' };
        }
        let updatedCategories = originalWsData.categories.filter(
            (cat) => cat.id !== catIdToMove
        );

        if (isSameWorkspace) {
            if (newIndex >= updatedCategories.length) {
                updatedCategories.push(movedCategory);
            } else {
                updatedCategories.splice(newIndex, 0, movedCategory);
            }
            batch.update(originalWsRef, {
                categories: updatedCategories,
                updateId: uuidv4()
            });
        } else {
            let groupsToMove = originalWsData.tabData.filter(
                (group) => group.categoryID === catIdToMove
            );
            if (groupsToMove.length) {
                originalWsData.tabData = originalWsData.tabData.filter(
                    (group) => group.categoryID !== catIdToMove
                );
                batch.update(originalWsRef, {
                    categories: updatedCategories,
                    tabData: originalWsData.tabData,
                    updateId: uuidv4()
                });
            } else {
                batch.update(originalWsRef, {
                    categories: updatedCategories,
                    updateId: uuidv4()
                });
            }

            let newWsCategories = newWsData.categories;
            if (newIndex < newWsCategories.length) {
                newWsCategories.splice(newIndex, 0, movedCategory); // Adjusted for clarity, could be optimized
            } else {
                newWsCategories.push(movedCategory);
            }
            if (groupsToMove.length) {
                newWsData.tabData.push(...groupsToMove);
                batch.update(newWsRef, {
                    categories: newWsCategories,
                    tabData: newWsData.tabData,
                    updateId: uuidv4()
                });
            } else {
                batch.update(newWsRef, {
                    categories: newWsCategories,
                    updateId: uuidv4()
                });
            }
        }
        await batch.commit();
        let payload = {};
        if (originalWsId === newWsId) {
            payload = { wsId1: originalWsId, wsId2: false };
        } else {
            payload = { wsId1: originalWsId, wsId2: newWsId };
        }
        let res = await syncCategoriesCall(payload);
        return { msg: 'success' };
    } catch (error) {
        console.error('Error moving category:', error);

        return { msg: 'error', error: error.message };
    }
};

export const getAllWSData = async () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                let userId = user.uid;
                unsubscribe(); // Detach the listener after we have the user
                let allData = [];

                try {
                    const itemsQuery = query(
                        collection(db, 'items'),
                        where('owner', '==', userId)
                    );
                    const itemsSnapshot = await getDocs(itemsQuery);

                    for (const docSnapshot of itemsSnapshot.docs) {
                        let data = docSnapshot.data();
                        if (data.tabData) {
                            allData.push(data);
                        }
                    }

                    resolve({ msg: 'success', allWSdata: allData });
                } catch (error) {
                    console.error('Error fetching data:', error);
                    resolve({ msg: 'error', error: error });
                }
            } else {
                unsubscribe(); // Make sure to clean up if there is no user
                resolve({ msg: 'error', error: 'No user logged in' });
            }
        });
    });
};
export const updateFullDeletedItemDb = async (newData) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            let userId = user.uid;
            unsubscribe();
            try {
                const deletedItemsRef = doc(db, 'deletedItems', userId);
                await setDoc(deletedItemsRef, { deletedItems: newData });
                return { msg: 'success' };
            } catch (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                return { msg: 'error', content: errorMessage };
            }
        } else {
            unsubscribe();
        }
    });
};

export const updateDeletedItemsDb = async (newData) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            let userId = user.uid;
            unsubscribe();
            try {
                const deletedItemsRef = doc(db, 'deletedItems', userId);
                const docSnap = await getDoc(deletedItemsRef);

                let mergedData;
                let updateData = {};
                if (docSnap.exists() && docSnap.data().deletedItems) {
                    mergedData = [...newData, ...docSnap.data().deletedItems];
                } else {
                    mergedData = newData;
                    updateData['documentId'] = userId;
                }

                if (mergedData.length > 50) {
                    mergedData = mergedData.slice(0, 50);
                }
                updateData['deletedItems'] = mergedData;

                await setDoc(deletedItemsRef, updateData, { merge: true });

                return { msg: 'success' };
            } catch (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                return { msg: 'error', content: errorMessage };
            }
        } else {
            unsubscribe();
        }
    });
};

export const addEmojiToDb = async (
    emojiList,
    userId,
    toast,
    onClose,
    setUserData
) => {
    try {
        const userDoc = doc(db, 'users', userId);
        const docSnap = await getDoc(userDoc);

        if (!docSnap.exists()) {
            throw new Error('Document does not exist!');
        }

        const data = docSnap.data();

        const existingEmojiList = data.uploadedEmojis || [];
        const mergedEmojiList = [...existingEmojiList, ...emojiList];
        let newUpdateID = uuidv4();
        await updateDoc(userDoc, {
            uploadedEmojis: mergedEmojiList,
            updateId: newUpdateID
        });
        setUserData({
            ...data,
            uploadedEmojis: mergedEmojiList,
            updateId: newUpdateID
        });

        toast({
            title: 'Successfully uploaded ' + emojiList[0].name,
            status: 'success',
            duration: 2900,
            isClosable: true
        });

        onClose();
    } catch (error) {
        console.log('error uploading', error);
        toast({
            title: 'Error uploading emoji',
            description: error.message,
            status: 'error',
            duration: 2900,
            isClosable: true
        });

        onClose();
    }
};
