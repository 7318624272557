import useStore from 'components/sharedCode/stores/store';

export const queryActiveTabs = async (onInit = false) => {};

export const highlightActiveTabs = (ids, windowId) => {};

//TODO type här, array of numbers?
export const highlightTabs = (indexsToHighlight, windowId = null) => {};

export const createTabGroup = (ids: any, windowId = null) => {};

export const unwrapTabFolder = (groupId: any) => {};

export const discardTabs = async (tabIdsToDiscard: any) => {};

export const reloadTabs = async (tabIdsToReload: any) => {};

export const togglePinTabs = async (tabIdsToToggle, shouldPin) => {};

export const findAllFoldersAndUpdate = (windowId) => {};

export const createTabGroupAndMove = (a, b) => {};

export const removeTabFromGroup = (a) => {};

export const updateMultipleTabGroups = (
    ids: any,
    options: any,
    updatedKey: any,
    windowId: string
) => {};

export const updateTabGroupTitle = (
    id: any,
    browserTitle: string,
    uiTitle: string,
    windowId: string
) => {};

export const updateTabGroupEmoji = (
    id: any,
    browserTitle: string,
    emoji: string,
    windowId: string
) => {};

export const updateTabGroup = (
    id: any,
    options: any,
    updatedKey: any,
    windowId: string
) => {};

export const updateTabGroupMultipleProps = (
    id: any,
    options: any,
    hasEmoji: boolean,
    emoji: string,
    utTitle: string,
    windowId: string
) => {};

export const moveTabs = async (
    ids: any,
    moveToIndex: number,
    toWindowId = null,
    toGroupId = null
) => {};

export const moveGroups = async (
    groupId: any,
    moveToIndex: number,
    toWindowId = null
) => {};
export const closeTabs = (
    ids: any,
    windowId: string,
    showNotification: boolean = false,
    refreshActiveTabs: boolean = true
) => {};
export const closeAllTabs = (windowId: string) => {};
export const openNewGroupFromFolder = async (
    folderItem: any,
    index: number = null,
    addToGroup = null,
    windowId: string = null // Add the windowId argument with a default value of null
) => {
    const setShowToast = useStore.getState().setShowToast;
    setShowToast(
        'Unable to open group',
        'Add the browser extension to open multiple sites at once',
        'info'
    );
    /* folderItem.tabs.forEach(async (item) => {
        if (item.url) {
            console.log('item', item.url);
            window.open(item.url, '_blank');
        }
    }); */
};

const createMultipleTabs = async (
    groupData,
    offsetPosition,
    windowId: string = null
) => {};

export const openNewItems = async (
    items,
    openInGroup: boolean,
    windowId: string,
    insertPosition = null,
    addToGroup = null
) => {};

export const addTabsToGroup = (groupId, tabArray, queryAfterDone = false) => {};

export const openNewTabsAndAddToGroup = async (groupId, items, windowId) => {};

export const updateContextMenu = (tree) => {};

export const openInNewTab = (urls) => {
    urls.forEach((url) => window.open(url, '_blank').focus());
};
export const openSingleTab = async (url, active = false) => {
    return new Promise<void>((resolve, reject) => {
        let newTab = window.open(url, '_blank');
        if (newTab) {
            resolve();
        } else {
            reject(new Error('Failed to open new tab.'));
        }
    });
};

export const updateSingleTab = (url) => {
    window.location.href = url;
};

export const openInNewWindow = (urls) => {
    if (!urls || urls.length === 0) return;

    // Open the first URL in a new window
    let newWindow = window.open(urls[0], '_blank');

    // Try to open the remaining URLs in new tabs of the same window
    for (let i = 1; i < urls.length; i++) {
        newWindow.open(urls[i]);
    }
};

export const updateCurrentTab = async (url) => {
    return new Promise<void>((resolve) => {
        window.location.href = url;
        resolve();
    });
};

export const openInIncognito = (urls) => {};
export const switchToTab = (id) => {};

export const broadcastToOtherFronts = (messageObject) => {};

export const groupTabsAI = async (allGroups, windowId) => {};

export const toggleMinMax = (isSidebar) => {};

export const chromeTabsRemove = (id) => {};
