import { showToast } from 'components/sharedCode/Utils/showToastWrapper';
import { useRemindersStore } from 'components/sharedCode/stores/remindersStore';
import useStore from 'components/sharedCode/stores/store';
import { subscribeDoc, subscribeUserDoc } from 'util/db';
import { initUserDataCall } from '../cloudFunctionCaller';
import { getStorageValue, setStorageValue } from '../utils/localStorage';

export const initDashboard = async () => {
    let userId = useStore.getState().authUser.uid;
    subscribeUserDoc(userId);
};

export const initDashboardLogic = async (userData) => {
    let userId = useStore.getState().authUser.uid;
    if (userData === null) {
        let res: any = await initUserDataCall();
        if (res?.data?.success) {
            subscribeUserDoc(userId);
        } else {
            showToast(
                'Something went wrong',
                'Unable to fetch userdata, please refresh',
                'error'
            );
        }
        return;
    }
    let wsDocId = await getCurrentLocalWs(userData);
    subscribeDoc(wsDocId, 'items');

    if (
        userData.stripeSubscriptionStatus &&
        userData.stripeSubscriptionStatus === 'active'
    ) {
        useRemindersStore.getState().fetchReminders();
    } else {
        useRemindersStore.getState().setRemindersData('noActivePlan');
    }
    useStore.setState({
        //@ts-ignore
        userData: userData,
        userStatus: 'signedIn'
    });
};

export const getCurrentLocalWs = async (userData = null) => {
    let localDocRef = await getStorageValue('localWSRef');
    // console.log('localDocRef', localDocRef);

    if (localDocRef && localDocRef !== 'null') {
        return localDocRef;
    } else {
        try {
            localDocRef = userData.workspaces[0].id;
            setStorageValue('localWSRef', localDocRef);
            return localDocRef;
        } catch (error) {
            showToast('Something went wrong 01', '', 'error');
            return;
        }
    }
};
