import useStore from '../sharedCode/stores/store';
import layoutStore from '../sharedCode/stores/layoutStore';
import usePersistStore from '../sharedCode/stores/persistStore';
import { showToast } from '../sharedCode/Utils/showToastWrapper';
import { isFreeUser } from '../sharedCode/components/userApi';
import useWsGridStore from '../sharedCode/stores/wsGridStore';
import {
    changeWorkspaceDb,
    createWorkspaceDb,
    deleteDeletedItemsDataByIdUpdate,
    moveCategoryDb,
    moveGroupDataDb,
    moveTabBetweenGroupsDb,
    updateCategoriesDb,
    updateDeletedItemsDb,
    updateFullDeletedItemDb
} from 'util/db';
import { deleteWorkspaceBg, updateTabDataDb } from './webSpecific/dataUpdates';
import {
    inviteUserToSharedCall,
    postFeedbackCall,
    removeUserFromSharedCall,
    resolveSharedInviteCall,
    shareWsPublicCall,
    updateWsMetaCall
} from './cloudFunctionCaller';
import {
    fetchWSRolesDb,
    getTeamEmailsDb,
    getUserIdData,
    getWsDataDb
} from './webSpecific/dataFetches';

export const changeWorkspaceFront = (
    setloadingWsData,
    WSid,
    categoryIndex
    //currentWsId
) => {
    setloadingWsData(categoryIndex);
    usePersistStore.getState().setSelectedCategory(categoryIndex);
    // layoutStore.getState().setWorkspaceZoom('zoomed-in');

    useStore.setState({
        tabGroups: null,
        groupsToShow: null,
        wsUpdateId: 'initStore',
        currentWsId: WSid,
        categories: null,
        changeWsLoading: true
    });
    changeWorkspaceDb(WSid);
};

export const createWorkspace = () => {
    useWsGridStore.setState({ newWsLoading: true });
    createWorkspaceDb().then((resp: any) => {
        useWsGridStore.setState({ newWsLoading: false });

        if (resp.success) {
            usePersistStore.getState().setSelectedCategory(0);
            useStore.getState().setUserDataWithKeyCheck(resp.data);
            showToast('New workspace created', '', 'success');
        } else {
            if (
                resp.error === 'Missing or insufficient permissions.' &&
                isFreeUser()
            ) {
                showToast(
                    'Workspace Limit Reached',
                    "As a free user, you're limited to 1 workspace. Upgrade to the Pro Plan for more workspaces.",
                    'error'
                );
            } else {
                showToast(
                    'Error',
                    'Something went wrong while creating workspace, please try again',
                    'error'
                );
            }
        }
    });
};

export const signUpEmailPassword = (
    email: string,
    password: string,
    setLoading
) => {};

export const signInEmailPassword = (
    email: string,
    password: string,
    setLoading
) => {};

export const signInGoogle = (setLoading) => {};

export const updateTabData = async (data) => {
    let resp = await updateTabDataDb(data.data, data.wsId, data.urlChanged);
    return resp;
};

export const updateWsMeta = (wsId, newData) => {
    updateWsMetaCall({
        wsId: wsId,
        newData: newData
    });
};

export const deleteDeletedItemsDataById = (idsToDelete) => {
    deleteDeletedItemsDataByIdUpdate(idsToDelete);
};

export const updateCategories = async (
    newCategories,
    newWsData,
    currentWsId
) => {
    let response = await updateCategoriesDb(newWsData, currentWsId);

    return response;
};

export const deleteWorkspace = async (payload) => {
    let resp = await deleteWorkspaceBg(payload);
    return resp;
};

export const moveGroupData = async (payload) => {
    let resp = await moveGroupDataDb(payload);
    return resp;
};

export const moveTabBetweenGroups = async (payload) => {
    let resp = await moveTabBetweenGroupsDb(payload);
    return resp;
};

export const getWsData = async (wsId) => {
    let resp = await getWsDataDb(wsId);
    return resp;
};

export const moveCategory = async (payload) => {
    let resp = await moveCategoryDb(payload);
    return resp;
};

export const reloadApp = () => {
    window.location.reload();
};

export const resolveSharedInvite = async (payload) => {
    let resp: any = await resolveSharedInviteCall(payload);
    return resp;
};

export const fetchWsRoles = async (wsId) => {
    let resp = await fetchWSRolesDb(wsId);
    return resp;
};

export const updateFullDeletedItem = async (newData) => {
    let resp = await updateFullDeletedItemDb(newData);
    return resp;
};
export const getDeletedItemsData = async () => {
    let resp = await getUserIdData('deletedItems');
    return resp;
};
export const updateDeletedItems = async (newData) => {
    let resp = await updateDeletedItemsDb(newData);
    return resp;
};

export const removeUserFromShared = async (payload) => {
    let resp: any = await removeUserFromSharedCall(payload);
    return resp;
};

export const sendInviteToWS = async (payload) => {
    let resp: any = await inviteUserToSharedCall(payload);
    return resp;
};

export const getTeamEmails = async (team) => {
    let resp = await getTeamEmailsDb(team);
    return resp;
};

export const resetPasswordMsg = async (resetEmail) => {
    // sendPasswordResetEmail()
    // let resp = await chrome.runtime.sendMessage({
    //     msg: 'sendPasswordResetEmail',
    //     email: resetEmail
    // });
    let resp: any = null;
    return resp;
};

export const shareWsPublic = async (payload) => {
    let resp: any = await shareWsPublicCall(payload);
    return resp;
};

export const createAnonAccount = async () => {
    // let resp = await chrome.runtime.sendMessage({
    //     msg: 'createAnonAccount'
    // });
    // return resp;
    let resp: any = null;
    return resp;
};

export const guestSignUpEmailPassword = (
    email: string,
    password: string,
    setLoading,
    isAlreadyGuest = false
) => {
    // setInnerLoading(true);
};

export const guestSignUpGoogle = (setLoading) => {};

export const getUserToken = async () => {
    let resp = null;
    return resp;
};

export const postFeedbackFront = async (payload) => {
    let resp: any = await postFeedbackCall(payload);
    return resp;
};
