/* global chrome */
import { create } from 'zustand';
import {
    initAppAction,
    setUserDataWithKeyCheckAction,
    signOutResetAction,
    signOutUserAction,
    updateUserDataAction,
    retakeTourAction,
    getUserTokenAsyncAction
} from '../../platformFiles/stores/userActions';
import { LoadingDataIds, TabData, UserData, WSdata, WsRoles } from 'types';
import {
    setGroupsToShowAction,
    updateTabGroupsAction,
    updateWsStateAction
} from './actions/workspaceActions';
import {
    addGroupAction,
    updateTabAction,
    createNoteAction,
    deleteNoteAction,
    submitNoteAction,
    turnNoteIntoSiteAction,
    deleteGroupAction,
    deleteItemsAction,
    updateGroupAction,
    updateWsMetaAction,
    changeWsIndexAction,
    updateCategoriesAction,
    duplicateGroupAction,
    copyContentAction,
    toggleMinimizeGroupAction,
    sortGroupAlphabeticallyAction,
    sortGroupByDateAction,
    deleteWorkspaceAction,
    moveGroupDataAction,
    hydrateCachedGroupsAction,
    moveTabToGroupAction,
    changeSpaceWidthAction,
    toggleMetaImagePinAction,
    saveActiveTabAction,
    moveCategoryAction,
    deleteCategoryAction,
    stackSelectedItemsAction,
    syncGroupSitesAction
} from './actions/spaceActions';
import {
    updateSingleActiveTabAction,
    updateSingleActiveFolderAction
} from './actions/itemActions';
import {
    removeItemFromBinAction,
    restoreItemFromBinAction,
    fetchDeletedItemsDataAction,
    saveDeletedDataAction
} from './actions/binActions';
import { setDoneStatusAction } from './actions/noteActions';

type UserStatus = 'signedIn' | 'signedOut' | 'loading' | 'guest';

type Category = {
    id: string;
    name: string;
    tabIndex?: number;
    shared?: boolean;
    slug?: string;
};

export interface ActiveTab {
    id: string;
    title: string;
    favIcon: string;
    url?: string;
    tabIndex: number;
    children?: Array<ActiveTab>;
    color?: any;
    emoji?: string;
    contentType: string;
    hasEmoji?: boolean;
    collapsed?: boolean;
}

export interface AllActiveTabs {
    [key: string]: { activeTabs: ActiveTab[]; numberOfTabs: number };
}

//TODO typea upp activeTabs arrayen(?) + array
interface activeTab {
    id: string;
}

type Store = {
    authUser: any;
    initApp: () => void;
    userStatus: UserStatus;
    userData: UserData;
    userPlan: string;
    trialLimitReached: boolean;
    trialSavesLeft: number;
    setUserStatus: (userStatus: UserStatus) => void;
    setUserData: (userData: UserData) => void;
    setUserDataWithKeyCheck: (userData: UserData) => void;
    updateUserData: (newUserData, updateDb?) => Promise<any>;
    retakeTour: () => void;
    wsUpdateId: string;
    tabGroups: TabData[];
    wsRoles: WsRoles[];
    wsOwner: string;
    setTabGroups: (newTabGroups) => void;
    groupsToShow: TabData[];
    setGroupsToShow: (catIndex) => void;
    currentTabId: number;
    updateWsState: (data: WSdata, keyCheck?: boolean) => void;
    toastState: null | {
        title: string;
        subtitle: string;
        type: string;
        resClick?: () => void;
    };
    setShowToast: (
        title: string,
        subtitle: string,
        type: string,
        resClick?: () => void
    ) => void;
    currentWsId: string;
    categories: Category[];
    signOut: () => void;
    signOutReset: () => void;
    reset: () => void;
    updateTabGroups: (
        newTabData: TabData[],
        loadingData?: LoadingDataIds,
        updateDb?: boolean,
        urlChanged?: boolean,
        debounce?: number
    ) => Promise<any>;
    updateGroup: (newTabData, groupId) => Promise<any>;
    updateWsMeta: (newWsData, wsId) => Promise<any>;
    changeWsIndex: (wsId, direction) => Promise<any>;
    updateTab: (
        newTabData,
        tabId,
        groupId,
        updateType?,
        debounceDelay?
    ) => Promise<any>;
    addGroup: () => void;
    createNote: (groupId) => void;
    deleteNote: (groupId, noteId, justAdded) => void;
    submitNote: (groupId, noteId, noteData, createAnother) => void;
    turnNoteIntoSite: (pastedData, prevValue, groupId, id, isImage) => void;
    allGroupsLoading: boolean;
    setAllGroupsLoading: (newStatus: boolean) => void;
    loadingGroups: { [key: string]: any };
    loadingItems: { [key: string]: any };
    //ACTIVE TABS STUFF:
    activeWindowId: number;
    setActiveWindowId: (id: number) => void;
    allActiveTabs: AllActiveTabs;
    allActiveFolderIds: {
        [key: string]: string;
    };
    setActiveFolderIdInWindow: (newId: string, windowId: string) => void;
    // activeFolderId: null | string;
    //getActiveFolderIndex: (windowId) => number;
    setAllActiveTabs: (newAllActiveTabs: AllActiveTabs) => void;
    setActiveTabsInWindow: (newActiveTabs: ActiveTab[], windowId: any) => void;
    updateSingleActiveTab: (newActiveTab: activeTab, status: string) => void;
    updateSingleActiveFolder: (group: activeTab) => void;
    changeWsLoading: boolean;
    saveDeletedData: (deletedData, type) => void;
    deleteGroup: (id) => void;
    deletedItemsData: any;
    fetchDeletedItemsData: () => void;
    resetDeletedItemsData: () => void;
    restoreItemFromBin: (deletedData, type) => void;
    removeItemFromBin: (idToDelete) => void;
    deleteItems: (idsToDelete) => void;
    updateCategories: (newCategories, newTabData?) => Promise<any>;
    duplicateGroup: (groupId) => void;
    copyContent: (spaceId) => void;
    toggleMinimizeGroup: (groupId, collapsedOrNot) => void;
    sortGroupAlphabetically: (groupId) => void;
    sortGroupByDate: (groupId) => void;
    deleteWorkspace: (wsId, isOwner) => void;
    workspacesUnderDeletion: any;
    moveGroupData: (groupToMove, toWsId, toCatId, toCatName) => void;
    cachedGroups: any;
    hydrateCachedGroups: (wsId: string) => void;
    moveTabToGroup: (tabId, wsId, groupId) => void;
    changeSpaceWidth: (groupId, width) => void;
    activeMetaCache: any;
    toggleMetaImagePin: (groupId, tabId, imageUrl) => Promise<any>;
    saveActiveTab: (activeTabId, groupId) => void;
    moveCategory: (
        originalWsId,
        newWsId,
        catIdToMove,
        newIndex,
        fallbackState,
        setFallbakState,
        setDragLoading
    ) => void;
    setDoneStatus: (
        status: boolean,
        groupId: string,
        id: string,
        hasAlarm: any
    ) => void;
    deleteCategory: (index) => void;
    isResizing: boolean;
    stackSelectedItems: () => void;
    allSubscriptions: any;
    addSubscription: (id: string, unsub: any) => void;
    debounceTimer: any;
    lastFocusedWindow: string | null;
    setLastFocusedWindow: (windowId: string) => void;
    syncGroupSites: (groupData, extraUrlsInGroup, removeUrls) => void;
    userToken: string;
    userTokenExpiration: any;
    getUserTokenAsync: () => Promise<any>;
};

const initialState = {
    authUser: null,
    userStatus: 'loading' as UserStatus,
    userData: null,
    userPlan: 'free',
    trialLimitReached: false,
    trialSavesLeft: 30,
    toastState: null,
    currentWsId: null,
    categories: null,
    wsUpdateId: 'initStore',
    tabGroups: null,
    wsRoles: null,
    wsOwner: null,
    groupsToShow: null,
    currentTabId: null,
    allGroupsLoading: true,
    loadingGroups: {},
    loadingItems: {},
    activeDesktop: null,
    activeWindowId: null,
    allActiveTabs: {},
    allActiveFolderIds: {},
    changeWsLoading: false,
    deletedItemsData: null,
    workspacesUnderDeletion: [],
    cachedGroups: {},
    activeMetaCache: {},
    isResizing: false,
    allSubscriptions: {},
    debounceTimer: null,
    lastFocusedWindow: null,
    userToken: null,
    userTokenExpiration: null
};

const useStore = create<Store>(
    (set, get): Store => ({
        ...initialState,
        initApp: () => {
            initAppAction(set, get);
        },
        setUserStatus: (newStatus: UserStatus) =>
            set({
                userStatus: newStatus
            }),
        setUserData: (userData: UserData) =>
            set((state) => ({
                ...state,
                userData
            })),
        setUserDataWithKeyCheck: (userData: UserData) => {
            setUserDataWithKeyCheckAction(get, set, userData);
        },
        updateUserData: (newUserData, updateDb = true) => {
            return updateUserDataAction(get, set, newUserData, updateDb);
        },
        retakeTour: () => {
            retakeTourAction(get);
        },
        setTabGroups: (newTabGroups) => {
            set({ tabGroups: newTabGroups });
        },
        setGroupsToShow: (catIndex) => {
            setGroupsToShowAction(set, get, catIndex);
        },
        updateWsState: (data, keyCheck = true) => {
            updateWsStateAction(set, get, data, keyCheck);
        },
        setShowToast: (title, subtitle, type, resClick = null) =>
            set((state) => ({
                toastState: {
                    title: title,
                    subtitle: subtitle,
                    type: type,
                    resClick: resClick
                }
            })),
        signOut: () => {
            signOutUserAction(set);
        },
        signOutReset: () => {
            signOutResetAction(get, set);
        },
        reset: () => set(initialState),
        updateTabGroups: (
            newTabData: TabData[],
            loadingData = null,
            updateDb = true,
            urlChanged = true,
            debounce = null
        ) => {
            return updateTabGroupsAction(
                set,
                get,
                newTabData,
                loadingData,
                updateDb,
                urlChanged,
                debounce
            );
        },
        updateGroup: (newTabData, groupId) => {
            return updateGroupAction(get, newTabData, groupId);
        },
        updateWsMeta: (newWsData, wsId) => {
            return updateWsMetaAction(get, newWsData, wsId);
        },
        changeWsIndex: (wsId, direction) => {
            return changeWsIndexAction(get, wsId, direction);
        },
        updateTab: (newTabData, tabId, groupId, updateType, debounceDelay) => {
            return updateTabAction(
                get,
                newTabData,
                tabId,
                groupId,
                updateType,
                debounceDelay
            );
        },
        addGroup: () => {
            addGroupAction(get);
        },
        createNote: (groupId) => {
            createNoteAction(get, set, groupId);
        },
        deleteNote: (groupId, noteId, justAdded) => {
            deleteNoteAction(get, groupId, noteId, justAdded);
        },
        submitNote: (groupId, noteId, noteData, createAnother) => {
            submitNoteAction(
                get,
                set,
                groupId,
                noteId,
                noteData,
                createAnother
            );
        },
        turnNoteIntoSite: (pastedData, prevValue, groupId, id, isImage) => {
            turnNoteIntoSiteAction(
                get,
                set,
                pastedData,
                prevValue,
                groupId,
                id,
                isImage
            );
        },
        setAllGroupsLoading: (newStatus) => {
            set({ allGroupsLoading: newStatus });
        },
        //ACTIVE TABS STUFF:
        setActiveWindowId: (windowId) =>
            set({
                activeWindowId: windowId
            }),
        setActiveFolderIdInWindow: (newId: string, windowId: string) => {
            let newAllActiveFolderIds = structuredClone(
                get().allActiveFolderIds
            );
            newAllActiveFolderIds[windowId] = newId;
            set({ allActiveFolderIds: newAllActiveFolderIds });
        },
        /* getActiveFolderIndex: (windowId) => {
            return getActiveFolderIndexAction(get, windowId);
        }, */
        setAllActiveTabs: (newAllActiveTabs: any) => {
            set({ allActiveTabs: newAllActiveTabs });
        },
        setActiveTabsInWindow: (newActiveTabs: ActiveTab[], windowId: any) => {
            let newAllActiveTabs = structuredClone(get().allActiveTabs);
            newAllActiveTabs[windowId].activeTabs = newActiveTabs;
            set({ allActiveTabs: newAllActiveTabs });
        },
        updateSingleActiveTab: (tab, status) => {
            updateSingleActiveTabAction(set, get, tab, status);
        },
        updateSingleActiveFolder: (group) => {
            updateSingleActiveFolderAction(set, get, group);
        },
        saveDeletedData: (deletedData, type) => {
            saveDeletedDataAction(deletedData, type);
        },
        deleteGroup: (id) => {
            deleteGroupAction(get, id);
        },
        fetchDeletedItemsData: () => {
            fetchDeletedItemsDataAction(set);
        },
        resetDeletedItemsData: () => {
            set({ deletedItemsData: null });
        },
        restoreItemFromBin: (deletedData, type) => {
            restoreItemFromBinAction(get, set, deletedData, type);
        },
        removeItemFromBin: (idToDelete) => {
            removeItemFromBinAction(get, set, idToDelete);
        },
        deleteItems: (idsToDelete) => {
            deleteItemsAction(get, idsToDelete);
        },
        updateCategories: (newCategories, newTabData = false) => {
            return updateCategoriesAction(get, set, newCategories, newTabData);
        },
        duplicateGroup: (groupId) => {
            duplicateGroupAction(get, groupId);
        },
        copyContent: (groupId) => {
            copyContentAction(get, groupId);
        },
        toggleMinimizeGroup: (groupId, collapsedOrNot) => {
            toggleMinimizeGroupAction(get, groupId, collapsedOrNot);
        },
        sortGroupAlphabetically: (groupId) => {
            sortGroupAlphabeticallyAction(get, groupId);
        },
        sortGroupByDate: (groupId) => {
            sortGroupByDateAction(get, groupId);
        },
        deleteWorkspace: (wsId, isOwner) => {
            deleteWorkspaceAction(get, set, wsId, isOwner);
        },
        moveGroupData: (groupToMove, toWsId, toCatId, toCatName) => {
            moveGroupDataAction(
                get,
                set,
                groupToMove,
                toWsId,
                toCatId,
                toCatName
            );
        },
        hydrateCachedGroups: (wsId) => {
            hydrateCachedGroupsAction(get, set, wsId);
        },
        moveTabToGroup: (tabId, wsId, groupId) => {
            moveTabToGroupAction(get, set, tabId, wsId, groupId);
        },
        changeSpaceWidth: (groupId, width) => {
            changeSpaceWidthAction(get, groupId, width);
        },
        toggleMetaImagePin: (groupId, tabId, imageUrl) => {
            return toggleMetaImagePinAction(get, groupId, tabId, imageUrl);
        },
        saveActiveTab: (activeTabId, groupId) => {
            saveActiveTabAction(get, activeTabId, groupId);
        },
        moveCategory: (
            originalWsId,
            newWsId,
            catIdToMove,
            newIndex,
            fallbackState,
            setFallbakState,
            setDragLoading
        ) => {
            moveCategoryAction(
                originalWsId,
                newWsId,
                catIdToMove,
                newIndex,
                fallbackState,
                setFallbakState,
                setDragLoading
            );
        },
        setDoneStatus: (
            status: boolean,
            groupId: string,
            id: string,
            hasAlarm: any
        ) => {
            setDoneStatusAction(get, set, status, groupId, id, hasAlarm);
        },
        deleteCategory: (index) => {
            deleteCategoryAction(get, index);
        },
        stackSelectedItems: () => {
            stackSelectedItemsAction(get);
        },
        addSubscription: (id: string, unsub: any) => {
            let newAllSubscriptions = get().allSubscriptions;
            newAllSubscriptions[id] = unsub;
            set({ allSubscriptions: newAllSubscriptions });
        },
        setLastFocusedWindow: (windowId) => {
            set({ lastFocusedWindow: windowId });
        },
        syncGroupSites: (groupData, extraUrlsInGroup, removeUrls) => {
            syncGroupSitesAction(get, groupData, extraUrlsInGroup, removeUrls);
        },
        getUserTokenAsync: () => {
            return getUserTokenAsyncAction(get, set);
        }
    })
);

export default useStore;
