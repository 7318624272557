/* global chrome */
import { v1 as uuidv1 } from 'uuid';
import { useRemindersStore } from '../remindersStore';
import {
    findGroupId,
    findItemInCurrentView
} from '../../Utils/findDataFunctions';
import { chromeTabsRemove } from '../../../platformFiles/browserAPI';

export const setDoneStatusAction = (
    get,
    set,
    isDone,
    spaceId,
    id,
    hasAlarm
) => {
    let item = findItemInCurrentView(id);
    let updatedItem = { ...item, doneStatus: isDone };
    get().updateTab(
        updatedItem,
        item.id,
        spaceId,
        {
            type: 'groups',
            ids: [spaceId]
        },
        300
    );
};

export const setAlarmAction = (get, spaceId, id, alarm) => {
    let newData = [...get().tabs];
    let spaceIndex = newData.findIndex((target) => target.id === spaceId);
    let tabIndex = newData[spaceIndex].tabs.findIndex(
        (target) => target.id === id
    );
    let userId = get().userState.userId;
    if (alarm) {
        if (newData[spaceIndex].tabs[tabIndex].hasAlarm) {
            let alarmIndex = newData[spaceIndex].tabs[
                tabIndex
            ].hasAlarm.findIndex((item) => item === userId);
            if (alarmIndex === -1) {
                newData[spaceIndex].tabs[tabIndex].hasAlarm.push(userId);
            }
        } else {
            newData[spaceIndex].tabs[tabIndex].hasAlarm = [userId];
        }
    }
    get().setTabs(newData, 'fullUpdate');
};

export const removeCommentAction = (get, spaceId, tabId) => {
    var newData = get().tabs;
    var spaceIndex = newData.findIndex((target) => target.id === spaceId);
    var tabIndex = newData[spaceIndex].tabs.findIndex(
        (target) => target.id === tabId
    );
    newData[spaceIndex].tabs[tabIndex].note = false;
    newData[spaceIndex].tabs[tabIndex].comment = '';
    get().setTabs(newData, 'deleteUpdate');
};

export const addNoteAction = (get, id, prevNoteId, prevNoteType) => {
    var newData = get().tabs;
    let userId = get().userState.userId;
    const newId = uuidv1();
    let dateobj = new Date().toString();
    let dateCreated = dateobj;
    let todo = prevNoteType ? true : false;
    let index = newData.findIndex((target) => target.id === id);
    let prevNoteIndex = prevNoteId
        ? newData[index].tabs.findIndex((target) => target.id === prevNoteId) +
          1
        : newData[index].tabs.length;
    let newNote = {
        id: newId,
        title: 'Note',
        favIcon: '',
        url: '',
        comment: '',
        commentColor: false,
        note: true,
        todo: todo,
        justAdded: true,
        dateCreated: dateCreated,
        editor: userId
    };
    newData[index].tabs.splice(prevNoteIndex, 0, newNote);
    //newData[index].tabs = newTabs;
    get().setTabs(newData, 'tempDbUpdate');
    get().setUserItems(get().userItems + 1);
    //setTabs(newData, newData => sendUpdateToDB(newData))
};

export const discardNoteAction = (get, spaceId, tabId) => {
    let newData = get().tabs;
    let spaceIndex = newData.findIndex((target) => target.id === spaceId);
    let deleted = newData[spaceIndex].tabs.filter((item) => item.id !== tabId);
    newData[spaceIndex].tabs = deleted;
    get().setUserItems(get().userItems - 1);
    get().setTabs(newData, 'tempDbUpdate');
};

export const restoreTabsAfterDeletedAction = (get, spaceIndex, tabsToRe) => {
    var newData = get().tabs;
    //var spaceIndex = newData.findIndex(target => target.id === spaceId)
    newData[spaceIndex].tabs = tabsToRe;
    get().setTabs(newData, 'deleteUpdate');
};

export const deleteOrCloseAction = (get, activeTabSelection, selectedItems) => {
    if (activeTabSelection === false) {
        deleteItems(get, selectedItems);
    } else {
        let newActiveTabs = get().activeTabs;
        selectedItems.forEach((id) => {
            let tabIndex = newActiveTabs.findIndex((item) => item.id === id);
            let tab = newActiveTabs[tabIndex];
            let browserId = tab.browserId;
            chromeTabsRemove(browserId);
            newActiveTabs.splice(tabIndex, 1);
        });
        get().setActiveTabs(newActiveTabs);
        //unselectAllAction();
    }
};

const deleteItems = (get, selectedItems) => {
    let tabs = get().tabs;
    let newTabs = get().tabs;
    let countLenght = selectedItems.length;
    let resArray = tabs.map((space, index) => null);
    let deletedItemArray = [];

    selectedItems.forEach((idToRemove) => {
        let spaceId = tabs.find((product) =>
            product.tabs.some((item) => item.id === idToRemove)
        ).id;
        let spaceIndex = tabs.findIndex((item) => item.id === spaceId);
        let filteredTabs = newTabs[spaceIndex].tabs.filter(
            (tab) => tab.id !== idToRemove
        );
        let deletedItem = newTabs[spaceIndex].tabs.filter(
            (tab) => tab.id === idToRemove
        );
        if (deletedItem[0].hasAlarm) {
            let clearReminder = useRemindersStore.getState().clearReminder;
            clearReminder(deletedItem[0].id, deletedItem[0].WSid);
        }
        deletedItemArray.push(deletedItem[0]);
        if (resArray[spaceIndex] === null) {
            resArray.splice(spaceIndex, 1, newTabs[spaceIndex].tabs);
        }
        newTabs[spaceIndex].tabs = filteredTabs;
    });
    get().setTabs(newTabs, 'deleteUpdate');
    if (deletedItemArray.length === 1) {
        get().setDeletedData([deletedItemArray[0]], 'item');
    } else {
        get().setDeletedData(deletedItemArray, 'item');
    }
    // Fetch the current reminders data outside the loop for efficiency
    let remindersData = useRemindersStore.getState().remindersData;
    let clearReminder = useRemindersStore.getState().clearReminder;

    // Filter items with hasAlarm and loop through each of them
    // Filter items with hasAlarm that is not empty and loop through each of them
    deletedItemArray
        .filter((item) => item.hasAlarm && item.hasAlarm.length > 0)
        .forEach((item) => {
            let reminderIndex = remindersData.findIndex(
                (reminder) => reminder.id === item.id
            );

            if (reminderIndex !== -1) {
                // Ensure the reminder exists
                let WSid = remindersData[reminderIndex].content.WSid
                    ? remindersData[reminderIndex].content.WSid
                    : null;
                clearReminder(item.id, WSid);
            }
        });
    let toastMessage =
        countLenght + (countLenght === 1 ? ' item deleted' : ' items deleted');
    get().setPrevTabs(resArray);
    get().setShowToast(toastMessage, resArray, 'restoreMultiple');
};
