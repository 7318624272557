import { broadcastToOtherFronts, queryActiveTabs } from '../browserAPI';
import { useRemindersStore } from '../../sharedCode/stores/remindersStore';
import { v4 as uuidv4 } from 'uuid';
import useStore from 'components/sharedCode/stores/store';
import { newUserDataDb } from '../webSpecific/dataUpdates';
import { signout } from 'util/auth';
import layoutStore from 'components/sharedCode/stores/layoutStore';
import { setStorageValue } from '../utils/localStorage';

export const initAppAction = (set, get) => {};

const setCurrentTabId = async (set) => {};

export const setUserDataWithKeyCheckAction = (get, set, newUserData) => {
    let oldUserData = get().userData;
    if (oldUserData?.updateId !== newUserData.updateId) {
        set({ userData: newUserData });
    }
};

export const updateUserDataAction = (
    get,
    set,
    newUserData,
    updateDb = true
) => {
    let userData = structuredClone(get().userData);
    let newUpdateId = uuidv4();
    newUserData.updateId = newUpdateId;
    Object.assign(userData, newUserData);
    set({ userData: userData });
    return new Promise((resolve, reject) => {
        if (updateDb) {
            newUserDataDb(newUserData).then((response) => {
                if (response?.msg === 'success') {
                    resolve(response); // Resolve the promise with the response
                } else {
                    reject(new Error('Failed to update tab data')); // Reject the promise if not successful
                }
            });
        } else {
            // If updateDb is false, immediately resolve the promise
            resolve({ msg: 'Database update not required' });
        }
    });
};

const signedInUserActions = (set, userCargo) => {
    set({ userData: userCargo });
    console.log('id: ', userCargo?.userId);
    set({ userStatus: 'signedIn' });
};

export const signOutUserAction = async (set) => {
    set({ userStatus: 'loading' });
    layoutStore.getState().setWorkspaceZoom('zoomed-in');
    setStorageValue('localWSRef', null);

    let result = await signout();
    set({ userStatus: 'signedOut' });
};

export const signOutResetAction = (get, set) => {
    get().reset();
    set({ userStatus: 'signedOut' });
};

export const userDataSubUpdate = (newUserData) => {
    let userDataOld = useStore.getState().userData;
    if (userDataOld && userDataOld.updateId !== newUserData.updateId) {
        useStore.setState({ userData: newUserData });
    }
};

export const retakeTourAction = (get) => {
    get().setUserData({ ...get().userData, tour: true });
};

export const getUserTokenAsyncAction = async (get, set) => {
    // let { userToken, userTokenExpiration } = get();
    // if (
    //     !userToken ||
    //     !userTokenExpiration ||
    //     Date.now() >= userTokenExpiration - 5 * 60 * 1000
    // ) {
    //     let resp = await getUserToken();
    //     if (resp.success) {
    //         let oneHourFromNow = Date.now() + 3600000;
    //         userToken = resp.token;
    //         set({ userToken: userToken, userTokenExpiration: oneHourFromNow });
    //     } else {
    //         userToken = null;
    //         set({ userToken: null, userTokenExpiration: null });
    //     }
    // }
    return null;
};
