import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { CommentIcon } from 'components/IconBlocks';
import { useRouter } from 'next/router';

function ChatBubble() {
    const router = useRouter();
    const shortPath = router.pathname.substring(0, 6);
    const isSharedPage = shortPath === '/share';
    if (isSharedPage) {
        return null;
    }
    return (
        <motion.div
            className="is-hidden-mobile"
            //ref={ref}
            onClick={() => Chatra('openChat', true)}
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.94 }}
            transition={{
                y: {
                    duration: 0.4,
                    delay: 0.3,
                    type: 'spring',
                    stiffness: 100,
                    bounce: 0.5
                },
                scale: {
                    duration: '0.2',
                    type: 'spring'
                }
            }}
            style={{
                background: '#FBFCFE',
                cursor: 'pointer',
                position: 'fixed',
                bottom: '1.5rem',
                right: '1.5rem',
                height: '62px',
                width: '62px',
                zIndex: '15',
                display: 'flex',
                alignItems: 'center',
                boxShadow:
                    '0 8px 16px -4px rgba(107,113,129,0.67), 0 24px 44px -26px rgba(194,200,206,0.80), inset 0 0 6px 0 #E5EAF3, inset -2px -2px 1px 0 #D5DAE5, inset 2px 2px 3px 0 rgba(255,255,255,0.17)',
                borderRadius: '50%'
            }}
        >
            <motion.div
                initial={{ scale: 0.25 }}
                animate={{ scale: 1 }}
                transition={{ /* duration: 0.4,  */ delay: 0.45 }}
                style={{ margin: 'auto', height: '34px', width: '34px' }}
            >
                <CommentIcon IconColor="#353C49" height="34px" width="34px" />
            </motion.div>
        </motion.div>
    );
}

export default ChatBubble;
