const defaultEmojis = [
    { emoji: '📚' },
    { emoji: '🌎' },
    { emoji: '🏕️' },
    { emoji: '💎' },
    { emoji: '🏖️' },
    { emoji: '🔗' },
    { emoji: '🥑' },
    { emoji: '🧀' },
    { emoji: '🍊' },
    { emoji: '🔑' },
    { emoji: '⛳' },
    { emoji: '💭' },
    { emoji: '🧺' },
    { emoji: '💽' },
    { emoji: '🍀' },
    { emoji: '💠' },
    { emoji: '🥬' },
    { emoji: '📥' },
    { emoji: '📦' },
    { emoji: '🥎' },
    { emoji: '🧪' },
    { emoji: '🏓' },
    { emoji: '🍋' },
    { emoji: '🎨' },
    { emoji: '🥪' },
    { emoji: '🌋' },
    { emoji: '📔' },
    { emoji: '🐝' },
    { emoji: '🛩️' },
    { emoji: '🧠' },
    { emoji: '🍇' },
    { emoji: '🍯' },
    { emoji: '🏰' },
    { emoji: '🐦' },
    { emoji: '🧥' },
    { emoji: '🐠' },
    { emoji: '🛒' },
    { emoji: '🧦' },
    { emoji: '🍎' },
    { emoji: '🍤' },
    { emoji: '🐟' },
    { emoji: '🧤' },
    { emoji: '🍒' },
    { emoji: '🌂' },
    { emoji: '🍓' },
    { emoji: '📌' },
    { emoji: '🐤' },
    { emoji: '🛎️' },
    { emoji: '🍔' },
    { emoji: '📍' }
];

export default defaultEmojis;
