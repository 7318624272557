import { firebaseApp } from 'util/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(firebaseApp);

export const initUserDataCall = async () => {
    const initUserDataFunc = httpsCallable(functions, 'initUserData');
    let res = await initUserDataFunc({});
    //@ts-ignore
    return res;
};

export const updateWsMetaCall = async (payload) => {
    const updateWsMetaFunc = httpsCallable(functions, 'updateWsMeta');
    let res = await updateWsMetaFunc(payload);
    return res;
};

export const syncCategoriesCall = async (payload) => {
    const syncCategoriesFunc = httpsCallable(functions, 'syncCategories');
    let res = await syncCategoriesFunc(payload);
    return res;
};
export const removeWorkspaceCall = async (payload) => {
    const removeWorkspaceFunc = httpsCallable(functions, 'removeWorkspace');
    let res = await removeWorkspaceFunc(payload);
    return res;
};
export const removeUserFromSharedCall = async (payload) => {
    const removeUserFromSharedFunc = httpsCallable(
        functions,
        'removeUserFromShared'
    );
    let res = await removeUserFromSharedFunc(payload);
    return res;
};

export const resolveSharedInviteCall = async (payload) => {
    const resolveSharedInviteFunc = httpsCallable(
        functions,
        'resolveSharedInvite'
    );
    let res = await resolveSharedInviteFunc(payload);
    return res;
};

export const inviteUserToSharedCall = async (payload) => {
    const inviteUserToSharedFunc = httpsCallable(functions, 'inviteToSharedWs');
    let res = await inviteUserToSharedFunc(payload);
    return res;
};

export const shareWsPublicCall = async (payload) => {
    const shareWsPublicFunc = httpsCallable(functions, 'shareWsPublic');
    let res = await shareWsPublicFunc(payload);
    return res;
};

export const migrateToV3Call = async () => {
    const migrateToV3Func = httpsCallable(functions, 'migrateToV3');
    let res: any = await migrateToV3Func({});
    //@ts-ignore
    return res;
};

export const postFeedbackCall = async (feedbackObj) => {
    const postFeedbackFunc = httpsCallable(
        functions,
        'postFeedbackFromExtension'
    );
    let res = await postFeedbackFunc({ feedbackObj });
    return res;
};
