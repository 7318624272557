import useStore from '../stores/store';

export const findGroup = (groups, groupId) => {
    for (const group of groups) {
        if (group.id === groupId) {
            return group;
        } else if (group.stackedGroup) {
            const found = group.stackedList.find(
                (subGroup) => subGroup.id === groupId
            );
            if (found) {
                return found;
            }
        }
    }
    return null;
};

// Helper function to find a tab within a group by tabId
export const findTab = (group, tabId) => {
    // Function to search recursively in the tabs and any stackedItems within them
    const searchTabs = (tabs) => {
        for (const tab of tabs) {
            if (tab.id === tabId) {
                return tab;
            }
            // If the tab is stacked, recursively search within its stackedItems
            if (tab.isStacked && tab.stackedItems) {
                const foundInStack = searchTabs(tab.stackedItems);
                if (foundInStack) {
                    return foundInStack;
                }
            }
        }
        return null; // Return null if no matching tab is found
    };

    // Start searching within the group's tabs
    return searchTabs(group.tabs);
};

export const findItemInCurrentView = (itemId) => {
    let groupsToShow = useStore.getState().groupsToShow;
    let item;
    for (let group of groupsToShow) {
        item = group.tabs.find((tab) => tab.id === itemId);
        if (item) {
            break;
        }
    }
    return item;
};

export const findGroupId = (itemId) => {
    let groupsToShow = useStore.getState().groupsToShow;
    let groupId;
    for (let g of groupsToShow) {
        if (g.tabs.find((tab) => tab.id === itemId)) {
            groupId = g.id;
            break;
        }
    }
    return groupId;
};
