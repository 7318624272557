import { create } from 'zustand';
import {
    clearAllRemindersAction,
    clearReminderAction,
    fetchRemindersAction,
    newReminderAction,
    setAlarmAction,
    updateReminderAction
} from '../../platformFiles/stores/remindersActions';

type Reminder = any;

type RemindersState = {
    due: any[];
    coming: any[];
    setDue: (data: any[]) => void;
    setComing: (data: any[]) => void;
    fetchReminders: () => void;
    newReminder: (
        newReminder: any,
        content: any,
        repeat: any,
        doesExist: any,
        selectedDays?: any[],
        dayNumberToRepeat?: any
    ) => void;
    updateReminder: (reminderId: any, updates: any) => void;
    clearReminder: (reminderToClear: any, WSid?: any) => void;
    clearRemindersFull: (remindersToClear: any) => Promise<void>;
    isRemindersModalOpen: any;
    setRemindersModal: (value: any) => void;
    remindersData: any;
    setRemindersData: (value: any) => void;
    hoverReminder: any;
    setHoverReminder: (value: any) => void;
    // removeAlarm: (WSid, id) => void;
    setAlarm: (spaceId, id, alarm) => void;
};

export const useRemindersStore = create<RemindersState>(
    (set, get): RemindersState => ({
        due: [],
        coming: [],
        setDue: (data) => set({ due: data }),
        setComing: (data) => set({ coming: data }),
        fetchReminders: () => {
            fetchRemindersAction(get, set);
        },
        newReminder: (
            newReminder,
            content,
            repeat,
            doesExist,
            selectedDays = null,
            dayNumberToRepeat = null
        ) => {
            newReminderAction(
                get,
                newReminder,
                content,
                repeat,
                doesExist,
                selectedDays,
                dayNumberToRepeat
            );
        },

        updateReminder: (reminderId, updates) => {
            updateReminderAction(get, reminderId, updates);
        },
        clearReminder: (reminderToClear, WSid = null) => {
            clearReminderAction(get, reminderToClear, WSid);
        },
        clearRemindersFull: (remindersToClear) => {
            return clearAllRemindersAction(get, remindersToClear);
        },
        isRemindersModalOpen: false,
        setRemindersModal: (value) =>
            set((state) => ({ isRemindersModalOpen: value })),
        remindersData: 'loading',
        setRemindersData: (value) => set((state) => ({ remindersData: value })),
        hoverReminder: null,
        setHoverReminder: (value) => set({ hoverReminder: value }),
        // removeAlarm: (WSid, id) => {
        //     removeAlarmAction(WSid, id);
        // },
        setAlarm: (spaceId, id, alarm) => {
            setAlarmAction(spaceId, id, alarm);
        }
    })
);
