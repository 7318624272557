import layoutStore from '../stores/layoutStore';
import useStore from '../stores/store';

export const isFreeUser = () => {
    return useStore.getState().userData.stripeSubscriptionStatus !== 'active';
};

export const updateTrialLimit = (tabGroups) => {
    let stripeSubscriptionStatus =
        useStore.getState().userData.stripeSubscriptionStatus;
    if (stripeSubscriptionStatus === 'active') {
        return;
    } else if (stripeSubscriptionStatus === 'paused') {
        useStore.setState({ trialLimitReached: true, trialSavesLeft: 0 });
    } else {
        let itemCount = 0;
        tabGroups.forEach((group) => {
            group.tabs?.forEach((tab) => {
                if (tab.isStacked) {
                    tab.stackedItems.forEach((tab) => {
                        itemCount++;
                    });
                } else {
                    itemCount++;
                }
            });
        });
        if (itemCount > 29) {
            useStore.setState({ trialLimitReached: true, trialSavesLeft: 0 });
        } else {
            useStore.setState({
                trialLimitReached: false,
                trialSavesLeft: 30 - itemCount
            });
        }
    }
};

export const isTrialReached = () => {
    if (useStore.getState().trialLimitReached) {
        layoutStore.getState().setShowLimitModal(true);
        return true;
    } else {
        return false;
    }
};
