import { showToast } from 'components/sharedCode/Utils/showToastWrapper';
import useStore from 'components/sharedCode/stores/store';
import { getRemindersData } from '../webSpecific/dataFetches';
import { setRemindersUpdate } from '../webSpecific/dataUpdates';
import { v1 as uuidv1 } from 'uuid';
import { clearRemindersUpdate } from 'util/db';

export const fetchRemindersAction = async (get, set) => {
    let response = await getRemindersData();
    if (response.reminders && response.reminders !== false) {
        get().setRemindersData(response.reminders);
    } else return;
};

export const newReminderAction = (
    get,
    newReminder,
    content,
    repeat,
    doesExist,
    selectedDays,
    dayNumberToRepeat
) => {
    const oldReminders = get().remindersData;
    let userId = useStore.getState().userData.userId;
    if (oldReminders === 'loading' || !userId) {
        const setShowToast = useStore.getState().setShowToast;
        setShowToast('Something went wrong setting reminder', null, 'error');
        return;
    }
    if (!repeat) {
        let completeData;

        if (doesExist) {
            // Find the index of the existing reminder
            const reminderIndex = oldReminders.findIndex(
                (reminder) => reminder.id === content.id
            );

            // Copy the old reminders array
            completeData = [...oldReminders];

            // Update the specific reminder in the copied array
            completeData[reminderIndex] = {
                id: content.id,
                date: newReminder,
                content: content,
                repeat: false
            };
        } else {
            let currentWSid = useStore.getState().currentWsId;

            // Add the WSid key-value pair to the content object
            const updatedContent = {
                ...content,
                WSid: currentWSid
            };
            // Create a new reminder object
            const newReminderObj = {
                id: content.id,
                date: newReminder,
                content: updatedContent,
                repeat: false
            };

            // Merge the new reminder into the array
            completeData = [...oldReminders, newReminderObj];
        }

        setRemindersUpdate(completeData).then((resp) => {
            if (resp.success) {
                const setAlarm = get().setAlarm;
                setAlarm(content.spaceId, content.id, true);
            }
        });
        get().setRemindersData(completeData);
    } else {
        // Handle repeating reminders
        let completeData;

        if (doesExist) {
            // Find the index of the existing reminder
            const reminderIndex = oldReminders.findIndex(
                (reminder) => reminder.id === content.id
            );

            // Copy the old reminders array
            completeData = [...oldReminders];

            // Update the specific reminder in the copied array
            completeData[reminderIndex] = {
                id: content.id,
                date: newReminder, // This will be the first occurrence date.
                content: content,
                repeat: true,
                selectedDays: selectedDays, // add selectedDays if present
                dayNumberToRepeat: dayNumberToRepeat // add dayNumberToRepeat if present
            };
        } else {
            let currentWSid = useStore.getState().currentWsId;

            // Add the WSid key-value pair to the content object
            const updatedContent = {
                ...content,
                WSid: currentWSid
            };
            // Create a new reminder object
            const newReminderObj = {
                id: content.id,
                date: newReminder,
                content: updatedContent,
                repeat: true,
                selectedDays: selectedDays, // add selectedDays if present
                dayNumberToRepeat: dayNumberToRepeat // add dayNumberToRepeat if present
            };

            // Merge the new reminder into the array
            completeData = [...oldReminders, newReminderObj];
        }

        setRemindersUpdate(completeData).then((resp) => {
            if (resp.success) {
                const setAlarm = get().setAlarm;
                setAlarm(content.spaceId, content.id, true);
            }
        });

        get().setRemindersData(completeData);
    }
};

export const updateReminderAction = (get, reminderId, updates) => {
    const oldReminders = get().remindersData;

    if (oldReminders === 'loading') {
        const setShowToast = useStore.getState().setShowToast;
        setShowToast('Something went wrong updating reminder', null, 'error');
        return;
    }

    // Find the index of the reminder to update
    const reminderIndex = oldReminders.findIndex(
        (reminder) => reminder.id === reminderId
    );

    if (reminderIndex === -1) {
        const setShowToast = useStore.getState().setShowToast;
        setShowToast('Reminder not found', null, 'error');
        return;
    }

    // Copy the old reminders array
    const updatedReminders = [...oldReminders];

    // Ensure the .content property exists
    if (!updatedReminders[reminderIndex].content) {
        updatedReminders[reminderIndex].content = {};
    }

    // Iterate through the updates object and merge them into the .content property
    for (const key in updates) {
        if (updates.hasOwnProperty(key)) {
            updatedReminders[reminderIndex].content[key] = updates[key];
        }
    }

    setRemindersUpdate(updatedReminders);
    get().setRemindersData(updatedReminders);
};

export const clearReminderAction = (get, id, WSid) => {
    const oldReminders = get().remindersData;
    if (oldReminders === 'loading') {
        const setShowToast = useStore.getState().setShowToast;
        setShowToast('Something went wrong clearing reminder', null, 'error');
        return;
    }
    let newReminders = [...oldReminders];
    const reminderToClear = oldReminders.find((reminder) => reminder.id === id);
    if (!reminderToClear) {
        console.error('Reminder not found with ID:', id);
        return; // exit the function or handle this case accordingly
    }

    if (reminderToClear.repeat) {
        const nextDate = getNextReminderDate(
            reminderToClear.date,
            reminderToClear.selectedDays,
            reminderToClear.dayNumberToRepeat
        );
        if (reminderToClear.dayNumberToRepeat) {
            const lastDayOfNextMonth = new Date(
                nextDate.getFullYear(),
                nextDate.getMonth() + 1,
                0
            ).getDate();
            if (reminderToClear.dayNumberToRepeat > lastDayOfNextMonth) {
                nextDate.setDate(lastDayOfNextMonth);
            } else {
                nextDate.setDate(reminderToClear.dayNumberToRepeat);
            }
        }
        let newDate =
            nextDate.toISOString().split('T')[0] +
            ' ' +
            reminderToClear.date.split(' ')[1]; // This keeps the time from the original reminder and sets the new date
        const newReminder = {
            ...reminderToClear,
            id: uuidv1(),
            date: newDate
        };
        newReminders.push(newReminder);
        let setShowToast = useStore.getState().setShowToast;
        setShowToast('New reminder created', 'Date: ' + newDate, 'success');
    }
    newReminders = newReminders.filter((reminder) => reminder.id !== id);

    setRemindersUpdate(newReminders).then((resp) => {
        // if (resp.success) {
        //     // if (!reminderToClear.repeat) {
        //     //     const removeAlarm = get().removeAlarm;
        //     //     removeAlarm(WSid, reminderToClear.content.id);
        //     // }
        // }
    });

    get().setRemindersData(newReminders);
};

export const setAlarmAction = (spaceId, id, alarm) => {
    let newData = structuredClone(useStore.getState().tabGroups);
    let spaceIndex = newData.findIndex((target) => target.id === spaceId);
    let tabIndex = newData[spaceIndex].tabs.findIndex(
        (target) => target.id === id
    );
    let userId = useStore.getState().userData.userId;
    if (alarm) {
        if (newData[spaceIndex].tabs[tabIndex].hasAlarm) {
            let alarmIndex = newData[spaceIndex].tabs[
                tabIndex
            ].hasAlarm.findIndex((item) => item === userId);
            if (alarmIndex === -1) {
                newData[spaceIndex].tabs[tabIndex].hasAlarm.push(userId);
            }
        } else {
            newData[spaceIndex].tabs[tabIndex].hasAlarm = [userId];
        }
    }

    useStore.getState().updateTabGroups(newData, {
        type: 'items',
        ids: [id]
    });
};

export const clearAllRemindersAction = async (get, remindersToClear) => {
    const oldReminders = get().remindersData;

    if (oldReminders === 'loading') {
        const setShowToast = useStore.getState().setShowToast;
        setShowToast('Something went wrong clearing reminder', null, 'error');
        return;
    }

    let reminderIdsToClear = [];
    if (remindersToClear === 'all') {
        oldReminders.forEach((reminder) => {
            reminderIdsToClear.push(reminder.id);
        });
    } else {
        reminderIdsToClear = remindersToClear;
    }

    let newReminders = oldReminders.filter(
        (reminder) => !reminderIdsToClear.includes(reminder.id)
    );
    get().setRemindersData(newReminders);

    // Wrap the sendMessage in a promise to use await
    const response = await clearRemindersUpdate({
        wsTabData: useStore.getState().tabGroups,
        reminderData: oldReminders,
        wsId: useStore.getState().currentWsId,
        remindersToClear: reminderIdsToClear,
        userId: useStore.getState().userData.userId,
        workspaces: useStore.getState().userData.workspaces
    });

    // Process the response
    if (response && response.msg === 'success') {
        if (remindersToClear === 'all') {
            let howmany = oldReminders.length;

            showToast(
                'All reminders cleared',
                `Cleared: ${howmany} reminders`,
                'success'
            );
        } else {
            // Handle partial clear success case if needed
        }
    } else {
        get().setRemindersData(oldReminders);

        showToast(
            'Error clearing reminders',
            'Something went wrong updating database, please refresh and try again',
            'error'
        );
    }
};

export const getNextReminderDate = (
    currentDate,
    selectedDays,
    dayNumberToRepeat
) => {
    const date = new Date(currentDate);

    if (selectedDays) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let currentDayIndex = date.getDay();

        while (true) {
            date.setDate(date.getDate() + 1);
            currentDayIndex = (currentDayIndex + 1) % 7;

            if (selectedDays.includes(daysOfWeek[currentDayIndex])) {
                return date;
            }
        }
    } else if (dayNumberToRepeat) {
        date.setMonth(date.getMonth() + 1);
        date.setDate(dayNumberToRepeat);
        return date;
    }
};
