import { create } from 'zustand';
import {
    resetStatesAction,
    setActiveItemByFetchAction,
    setAnimationCompleteAction,
    setBlurOnDropIdsAction,
    setGlobalIsDraggingAction,
    setHideGridItemIdsAction,
    setNewDragEndIdAction
} from './actions/dndActions';

export interface InsertPosition {
    [key: string]: string;
}

interface ItemDisplacement {
    Y: number;
    X: number;
}
export interface ActiveItem {
    [key: string]: string;
}

type DndStore = {
    activeItem: any;
    setActiveItem: (activeItem: ActiveItem) => void;
    activeItemLayout: string;
    setActiveItemLayout: (newActiveItemLayout: string) => void;
    dragItems: any[];
    dragItemsConfirmed: { [key: string]: boolean };
    dragIds: string[];
    dragContainerId: { [key: string]: boolean };
    setDragContainerId: (containerId: any) => void;
    setDragItems: (newDragItems: any[]) => void;
    dndReverseData: any;
    dragEndAnimation: { [key: string]: { x: number; y: number } };
    setDragEndAnimation: (newDragEndAnimation: {
        [key: string]: { x: number; y: number };
    }) => void;
    dragEndAnimationResolve: any;
    setDragEndAnimationResolve: (newdDragEndAnimationResolve: any) => void;
    setAnimationComplete: () => void;
    resetStates: () => void;
    dragItemDisplacement: ItemDisplacement;
    setDragItemDisplacement: (
        newDragItemDisplacement: ItemDisplacement
    ) => void;
    insertPosition: InsertPosition;
    setInsertPosition: (insertPosition: InsertPosition) => void;
    containerInsertPosition: InsertPosition;
    setContainerInsertPosition: (insertPosition: InsertPosition) => void;
    globalIsDragging: boolean;
    globalGroupIsDragging: boolean;
    setGlobalIsDragging: (newGlobalIsDragging: boolean) => void;
    newDragEndId: { [key: string]: string };
    setNewDragEndId: (oldId: string, newId: string) => void;
    isDropping: boolean;
    setIsDropping: (isDropping: boolean) => void;
    blurOnDropIds: object;
    setBlurOnDropIds: (newBlurOnDropIds: string[]) => void;
    activeZu: any;
    overContainerZu: any;
    hideDragIds: { [key: string]: boolean };
};

const useDndStore = create<DndStore>(
    (set, get): DndStore => ({
        activeItem: null,
        setActiveItem: (newActiveItem: ActiveItem) => {
            set({ activeItem: newActiveItem });
        },
        activeItemLayout: null,
        setActiveItemLayout: (newActiveItemLayout: string) => {
            set({ activeItemLayout: newActiveItemLayout });
        },
        insertPosition: {},
        setInsertPosition: (newInsertPosition: InsertPosition) => {
            set({ insertPosition: newInsertPosition });
        },
        containerInsertPosition: {},
        setContainerInsertPosition: (newInsertPosition: InsertPosition) => {
            set({ containerInsertPosition: newInsertPosition });
        },
        globalIsDragging: false,
        globalGroupIsDragging: false,
        setGlobalIsDragging: (newGlobalIsDragging: boolean) => {
            setGlobalIsDraggingAction(get, set, newGlobalIsDragging);
        },
        dragItemDisplacement: null,
        setDragItemDisplacement: (
            newDragItemDisplacement: ItemDisplacement
        ) => {
            set({
                dragItemDisplacement: newDragItemDisplacement
            });
        },
        resetStates: () => {
            resetStatesAction(set, get);
        },
        newDragEndId: {},
        setNewDragEndId: (oldId: string, newId: string) => {
            setNewDragEndIdAction(get, set, oldId, newId);
        },
        isDropping: false,
        setIsDropping: (isDropping: boolean) => {
            set({ isDropping: isDropping });
        },
        dragItems: [],
        dragItemsConfirmed: {},
        dragIds: [],
        dragContainerId: {},
        setDragContainerId: (containerId: any) => {
            set({ dragContainerId: containerId });
        },
        setDragItems: (newDragItems: any[]) => {
            set({ dragItems: newDragItems });
        },
        dndReverseData: null,
        dragEndAnimation: {},
        setDragEndAnimation: (newDragEndAnimation) => {
            set({ dragEndAnimation: newDragEndAnimation });
        },
        dragEndAnimationResolve: null,
        setDragEndAnimationResolve: (newDragEndAnimationResolve: any) => {
            set({ dragEndAnimationResolve: newDragEndAnimationResolve });
        },
        setAnimationComplete: () => {
            setAnimationCompleteAction(get, set);
        },
        blurOnDropIds: {},
        setBlurOnDropIds: (newBlurOnDropIds: string[]) => {
            // set({ blurOnDropIds: newBlurOnDropIds });
            setBlurOnDropIdsAction(set, newBlurOnDropIds);
        },
        activeZu: null,
        overContainerZu: null,
        hideDragIds: {}
    })
);

export default useDndStore;
