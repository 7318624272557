import { WSdata } from 'types';
import { v4 as uuidv4 } from 'uuid';
import layoutStore from '../layoutStore';
import { updateTrialLimit } from '../../../sharedCode/components/userApi';
import { showToast } from '../../../sharedCode/Utils/showToastWrapper';
import { updateTabData } from '../../../platformFiles/platformMessages';

export const setGroupsToShowAction = (set, get, newCategory) => {
    // const currentCategoryId = categories[selectedCategory]?.id;
    // const groupsToShow = tabGroups?.filter(
    //     (g) => g.categoryID === currentCategoryId
    // );
    set({ allGroupsLoading: true });
    let currentCategoryID = get().categories[newCategory].id;
    let groupsToShow = get().tabGroups.filter(
        (g) => g.categoryID === currentCategoryID
    );
    set({ groupsToShow: groupsToShow });
    set({ allGroupsLoading: false });
};

export const updateWsStateAction = (
    set,
    get,
    data: WSdata,
    keyCheck = true
) => {
    let wsUpdateId = get().wsUpdateId;
    if (keyCheck && wsUpdateId === data.updateId) {
        return;
    } else {
        updateTrialLimit(data.tabData);
        if (
            get().changeWsLoading &&
            layoutStore.getState().workspaceZoom !== 'zoomed-in'
        ) {
            set({
                tabGroups: data.tabData,
                wsUpdateId: data.updateId,
                currentWsId: data.WSid,
                categories: data.categories,
                wsOwner: data.owner,
                wsRoles: data.roles,
                changeWsLoading: false
            });
            layoutStore.getState().setWorkspaceZoom('zoomed-in');
        } else {
            set({
                tabGroups: data.tabData,
                wsUpdateId: data.updateId,
                currentWsId: data.WSid,
                categories: data.categories,
                wsOwner: data.owner,
                wsRoles: data.roles,
                changeWsLoading: false
            });
        }
    }
};
let debounceTimer;
const cleanupFunctions = [];

export const updateTabGroupsAction = async (
    set,
    get,
    newTabs,
    loadingData,
    updateDb = true,
    urlChanged = true,
    debounceDelay = null
) => {
    let wsUpdateId = uuidv4();
    set({
        tabGroups: newTabs,
        wsUpdateId: wsUpdateId
    });
    updateTrialLimit(newTabs);
    const executeCleanupOperations = () => {
        cleanupFunctions.forEach((cleanup) => cleanup());
        cleanupFunctions.length = 0;
    };
    // Return a new promise
    return new Promise((resolve, reject) => {
        if (updateDb) {
            setLoadingDataState(get, set, loadingData, 'add');
            cleanupFunctions.push(() =>
                setLoadingDataState(get, set, loadingData, 'delete')
            );
            const sendMessage = () => {
                updateTabData({
                    msg: 'updateTabData',
                    data: { updateId: wsUpdateId, tabData: newTabs },
                    wsId: get().currentWsId,
                    urlChanged: urlChanged
                }).then((response) => {
                    executeCleanupOperations();
                    if (response?.msg === 'success') {
                        resolve(response); // Resolve the promise with the response
                    } else {
                        if (
                            response?.msg === 'error' &&
                            response?.detail ===
                                'Document size exceeds the maximum allowed size.'
                        ) {
                            showToast(
                                'Workspace Limit Reached',
                                'Your workspace has exceeded its storage capacity in the database. To resolve this, consider redistributing content by moving some categories or groups to a new workspace.',
                                'error'
                            );
                        }
                        reject(new Error('Failed to update tab data')); // Reject the promise if not successful
                    }
                });
                // chrome.runtime.sendMessage(
                //     {
                //         msg: 'updateTabData',
                //         data: { updateId: wsUpdateId, tabData: newTabs },
                //         wsId: get().currentWsId,
                //         urlChanged: urlChanged
                //     },
                //     (response) => {
                //         // setLoadingDataState(get, set, loadingData, 'delete');
                //         executeCleanupOperations();
                //         if (response?.msg === 'success') {
                //             resolve(response); // Resolve the promise with the response
                //         } else {
                //             if (
                //                 response?.msg === 'error' &&
                //                 response?.detail ===
                //                     'Document size exceeds the maximum allowed size.'
                //             ) {
                //                 showToast(
                //                     'Workspace Limit Reached',
                //                     'Your workspace has exceeded its storage capacity in the database. To resolve this, consider redistributing content by moving some categories or groups to a new workspace.',
                //                     'error'
                //                 );
                //             }
                //             reject(new Error('Failed to update tab data')); // Reject the promise if not successful
                //         }
                //     }
                // );
            };

            if (debounceDelay !== null) {
                clearTimeout(debounceTimer); // Clear the previous timer
                debounceTimer = setTimeout(sendMessage, debounceDelay); // Set a new timer
            } else {
                sendMessage(); // No debounce, send message immediately
            }
        } else {
            // If updateDb is false, immediately resolve the promise
            resolve({ msg: 'Database update not required' });
        }
    });
};

const setLoadingDataState = (get, set, loadingData, type) => {
    if (loadingData === null) {
        return;
    }
    if (type === 'add') {
        if (loadingData.type === 'groups') {
            let loadingState = get().loadingGroups;
            loadingData.ids.forEach((id) => (loadingState[id] = true));
            set({ loadingGroups: { ...loadingState } });
        } else if (loadingData.type === 'items') {
            let loadingState = get().loadingItems;
            loadingData.ids.forEach((id) => (loadingState[id] = true));
            set({ loadingItems: { ...loadingState } });
        }
    } else {
        if (loadingData.type === 'groups') {
            let loadingState = get().loadingGroups;
            loadingData.ids.forEach((id) => delete loadingState[id]);
            set({ loadingGroups: { ...loadingState } });
        } else if (loadingData.type === 'items') {
            let loadingState = get().loadingItems;
            loadingData.ids.forEach((id) => delete loadingState[id]);
            set({ loadingItems: { ...loadingState } });
        }
    }
};
