import { runes } from 'runes2';
import usePersistStore from '../stores/persistStore';
import { openSingleTab, updateSingleTab } from '../../platformFiles/browserAPI';
/* import dayjs from 'dayjs'; */

/* export const dayJsFormatedDate = (dateString) => {
    let date = new Date(dateString);
    const isYesterday = dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day');
    const isToday = dayjs(date).isSame(dayjs(), 'day'); // dayjs() return current date
    let dayJsDayString = isToday
        ? 'Today'
        : isYesterday
        ? 'Yesterday'
        : dayjs(date).format(
              'MMM D, YYYY'
              // 'MMM D, YYYY at HH:mm'
          );
    let fullString = dayJsDayString + ' at ' + dayjs(date).format('HH:mm');
    return fullString;
}; */

export const extractEmoji = (title: string) => {
    const emojiRegex = /\p{Extended_Pictographic}/gu;
    //@ts-ignore
    let firstChar = runes.substr(title, 0, 1);
    let hasEmoji = emojiRegex.test(firstChar);
    let slicedTitle = runes
        //@ts-ignore
        .substr(title, 1)
        .trim();

    return { firstChar, hasEmoji, slicedTitle };
};

export function shortUrl(url: string): string {
    return url
        .replace(/(^\w+:|^)\/\//, '')
        .replace('www.', '')
        .replace(/\/$/, '');
}

export const openLink = (e: MouseEvent, url: string, setLoadingState: any) => {
    e.stopPropagation();
    if (e.metaKey || e.ctrlKey || e.which === 2 || e.button === 4) {
        openSingleTab(url).then(() => {
            setLoadingState(false);
        });
        return;
    } else if (usePersistStore.getState().openNewTabState) {
        openSingleTab(url, usePersistStore.getState().jumpNewTabState).then(
            () => {
                setLoadingState(false);
            }
        );
        return;
    } else {
        updateSingleTab(url);
        setLoadingState(true);
    }
};
