// import {
//     sendErrorToFront,
//     updateMultipleFront
// } from 'background/bg-messages/bgUtils';
// import {
//     changeWorkspaceDb,
//     getDocWithError,
//     getUserDataDoc,
//     getUserIdDoc,
//     joinWorkspaceDb,
//     movePublicDataDb,
//     removeWorkspaceDb,
//     setDocInCol,
//     setDocumentSimple,
//     setUserDoc,
//     updateAllSharedDocs,
//     updateSharedWSDb
// } from './dbutils';
// import { getUrls, getUserDataToFront } from './dataFetches';
// import {
//     removeUserFromSharedCall,
//     removeWorkspaceCall
// } from 'background/bg-messages/cloudFunctionClientBg';

import {
    changeWorkspaceDb,
    setDocInCol,
    setDocumentSimple,
    setUserDoc
} from 'util/db';
import { getUrls } from './dataFetches';
import {
    removeUserFromSharedCall,
    removeWorkspaceCall
} from '../cloudFunctionCaller';

export const setRemindersUpdate = async (newReminders) => {
    try {
        let resp = await setDocInCol({ reminders: newReminders }, 'reminders');
        return { success: true };
    } catch (e) {
        return { error: true, errorMessage: e.message };
    }
};

// export const deleteDeletedItemsDataByIdUpdate = async (
//     idsToDelete,
//     sendResponse
// ) => {
//     try {
//         let deletedItemsData: any = await getUserIdDoc('deletedItems');
//         let filteredDeletedItems = deletedItemsData.deletedItems.filter(
//             (item) => !idsToDelete.includes(item.deletedData.id)
//         );
//         setDocInCol(
//             { deletedItems: filteredDeletedItems },
//             'deletedItems',
//             sendResponse,
//             false,
//             sendResponse({ msg: 'success' }),
//             true
//         );
//     } catch (error) {
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         sendErrorToFront(errorMessage, errorCode, 7);
//     }
// };

// export const saveWSinfo = (sendResponse, ws) => {
//     if (ws.workspaces !== false) {
//         saveNewWSdata(ws);
//     }
//     sendResponse({});
// };

// export const joinWorkspace = async (req, sendResponse) => {
//     let { ws, docRef, email, name } = req;
//     if (req.ws.workspaces !== false) {
//         joinWorkspaceDb(ws, docRef, email, name);
//     }
//     sendResponse({});
// };

// export const removeWorkspace = async (
//     req,
//     sendResponse,
//     subscriptionCollection
// ) => {
//     if (req.ws.workspaces !== false) {
//         let {
//             ws,
//             docRef,
//             email,
//             isCurrentLetsChange,
//             changeToWsId,
//             changeFromId
//         } = req;
//         removeWorkspace;
//         if (isCurrentLetsChange === true) {
//             changeWorkspaceDb(changeToWsId, subscriptionCollection);
//         }

//         removeWorkspaceDb(ws, docRef, email);
//     }
//     sendResponse({});
// };
// export const saveNewWSdata = async (data) => {
//     await setUserDoc(data);
//     getUserDataToFront();
// };

// export const sendInviteToWS = async (req, sendResponse) => {
//     saveNewWSdata(req.newWSdata);
//     updateSharedWSDb(
//         req.WSid,
//         req.shareWith,
//         req.isCurrentWS,
//         req.hasWsIdOne,
//         req.newWsId
//     );
//     sendResponse({ msg: 'inviteSent' });
// };

// export const updateUserEmail = async (data) => {
//     let newEmail = data.email;
//     await setUserDoc({ email: newEmail }, 20);
//     await getUserDataToFront();
// };

export const newUserDataDb = async (data) => {
    try {
        await setUserDoc(data);
        return { msg: 'success' };
    } catch (e) {
        console.error('Error updating userdata', e);
        return { msg: 'error', error: e.message };
    }
};

// export const newUserAndSharedData = async (
//     data,
//     sendResponse,
//     proPlan,
//     newName
// ) => {
//     newUserData(data, sendResponse);
//     if (proPlan === true) {
//         updateAllSharedDocs(newName);
//     }
//     sendResponse({});
// };

// export const movePublicData = (req, sendResponse) => {
//     movePublicDataDb(
//         req.fromCatSlug,
//         req.toCatSlug,
//         req.fromData,
//         req.allTabData,
//         req.WSid,
//         req.payload
//     );
//     let currentWSid = req.WSid;
//     // updateRefData(req.wsData, 'tab', currentWSid, sendResponse);
// };

export const updateTabDataDb = async (newData, currentWsId, urlChanged) => {
    if (urlChanged) {
        let urlArray = getUrls(newData.tabData);
        newData = { ...newData, ...{ urls: urlArray } };
    }

    try {
        let resp = await setDocumentSimple(currentWsId, 'items', newData);
        return { msg: 'success' };
    } catch (error) {
        console.error(error);
        if (
            error.code === 'invalid-argument' &&
            error.message.includes('exceeds the maximum allowed size')
        ) {
            return {
                msg: 'error',
                detail: 'Document size exceeds the maximum allowed size.'
            };
        } else {
            return { msg: 'error', detail: 'An error occurred.' };
        }
    }
};

export const deleteWorkspaceBg = (payload) => {
    const { wsId, isOwner, userToRemove, changeToWs } = payload;
    if (changeToWs) {
        changeWorkspaceDb(changeToWs);
    }
    if (isOwner) {
        return removeWorkspaceCall({ wsId });
    } else {
        return removeUserFromSharedCall({
            userToRemove: userToRemove,
            wsId: wsId
        });
    }
};
