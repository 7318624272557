import { extractEmoji } from '../../Utils/tabUtils';

export const updateSingleActiveTabAction = (set, get, tab, status) => {
    if (get().activeWindowId !== tab.windowId) {
        return;
    } else {
        let activeTabs = [...get().allActiveTabs[tab.windowId].activeTabs];
        let doesExistIndex = activeTabs.findIndex(
            (activeTab) => activeTab.id === tab.id + ''
        );
        if (doesExistIndex !== -1) {
            //update stuff
            let tabData = {
                id: tab.id + '',
                title: tab.title,
                favIcon: tab.favIconUrl,
                url: tab.url,
                tabIndex: tab.index,
                contentType: 'site',
                status: status,
                discarded: tab.discarded
            };
            activeTabs[doesExistIndex] = tabData;
            get().setActiveTabsInWindow(activeTabs, tab.windowId);
        } else {
            //Search children of all groups
            //add to activeTabs
        }
    }
};

export const updateSingleActiveFolderAction = (set, get, folder) => {
    let activeTabs = [...get().allActiveTabs[folder.windowId].activeTabs];
    let doesExistIndex = activeTabs.findIndex(
        (activeFolder) => activeFolder.id === folder.id + ''
    );
    let { firstChar, hasEmoji, slicedTitle } = extractEmoji(folder.title);
    if (doesExistIndex !== -1) {
        let newFolderData = {
            collapsed: folder.collapsed,
            color: folder.color,
            title: folder.title
                ? hasEmoji
                    ? slicedTitle.length > 0
                        ? slicedTitle
                        : 'Untitled folder'
                    : folder.title
                : 'Untitled folder',
            browserTitle: folder.title ? folder.title : 'Untitled folder'
        };
        let allFolderData = { ...activeTabs[doesExistIndex], ...newFolderData };
        activeTabs[doesExistIndex] = allFolderData;
        get().setActiveTabsInWindow(activeTabs, folder.windowId);
    }
};
