// export const getInitialUserData = async (sendResponse, initialUserDataSent) => {
//     try {
//         if (initialUserDataSent === false) {
//             let userData = await getUserDataDoc();
//             sendResponse({ userData });
//             initialUserDataSent = true;
//         } else {
//             sendResponse({ userData: false });
//         }
//     } catch (error) {
//         var errorCode = error?.code;
//         var errorMessage = error?.message;
//         sendErrorToFront(errorMessage, errorCode, 7);
//         sendResponse({});
//     }
// };

import { getDocWithError, getUserIdDoc } from 'util/db';

// export const getUserDataToFront = async () => {
//     try {
//         let data = await getUserDataDoc();
//         chrome.tabs.query(
//             {
//                 url: ['chrome://newtab/', extensionUrl]
//             },
//             function (tab) {
//                 tab.forEach((tab) =>
//                     chrome.tabs.sendMessage(tab.id, {
//                         msg: 'newUserData',
//                         data
//                     })
//                 );
//             }
//         );
//     } catch (error) {
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         sendErrorToFront(errorMessage, errorCode, 7);
//     }
// };

// export const getDocumentData = async (docId, collection, sendResponse) => {
//     try {
//         let docData = await getDocWithError(collection, docId);
//         sendResponse({ docData });
//     } catch (error) {
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         sendErrorToFront(errorMessage, errorCode, 7);
//         sendResponse({});
//     }
// };

export const getUserIdData = async (collection) => {
    try {
        let docData = await getUserIdDoc(collection);
        return { docData };
    } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        return {};
    }
};

export const getRemindersData = async () => {
    try {
        let docData: any = await getUserIdDoc('reminders');
        const reminders = docData.reminders;
        return { reminders };
    } catch (error) {
        return { reminders: [] };
    }
};

export const getUrls = (tabData) => {
    // Merge the results into tempTabData
    let urlArray = [];
    if (tabData) {
        tabData.forEach((group) => {
            if (group?.tabs) {
                group.tabs.forEach((tab) => {
                    if (tab?.isStacked) {
                        tab.stackedItems.forEach((tabInStack) => {
                            if (tabInStack?.url) {
                                let urlInStackClean = cleanUrl(tabInStack.url);
                                if (urlInStackClean) {
                                    urlArray.push(urlInStackClean);
                                }
                            }
                        });
                    } else if (tab?.url) {
                        let urlClean = cleanUrl(tab.url);
                        if (urlClean) {
                            urlArray.push(urlClean);
                        }
                    }
                });
            }
        });
    }

    return urlArray;
};
export const cleanUrl = (url) => {
    if (url.length > 400) {
        return false;
    }
    let urlClean = url.split('#')[0];
    urlClean = urlClean.endsWith('/') ? urlClean.slice(0, -1) : urlClean;
    return urlClean;
};

export const fetchWSRolesDb = async (wsid) => {
    try {
        let data: any = await getDocWithError('items', wsid);
        return data.roles ? data.roles : [];
    } catch (error) {
        console.log('error', error);
        var errorCode = error.code;
        var errorMessage = error.message;
        return { msg: 'error' };
    }
};

export const getTeamEmailsDb = async (teamId) => {
    try {
        let data: any = await getDocWithError('teams', teamId);
        return { msg: 'success', members: data.members };
    } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        return {};
    }
};

export const getTeamData = async (teamId) => {
    try {
        let data: any = await getDocWithError('teams', teamId);
        return { data };
    } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        return {};
    }
};

// export const fetchPublicCat = async (slug, sendResponse) => {
//     try {
//         let data: any = await getDocWithError('shared', slug);
//         sendResponse({
//             msg: 'success',
//             data: data.tabData,
//             public: data.public,
//             viewers: data.viewers ? data.viewers : []
//         });
//     } catch (error) {
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         sendErrorToFront(errorMessage, errorCode, 124);
//         sendResponse({});
//     }
// };

// export const fetchWsRoles = async (wsId, sendResponse) => {
//     try {
//         let data: any = await getDocWithError('items', wsId);
//         sendResponse({
//             msg: 'success',
//             roles: data.roles ? data.roles : null,
//             owner: data.owner
//         });
//     } catch (error) {
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         sendResponse({ msg: 'error', error: error.message });
//     }
// };

export const getWsDataDb = async (wsId) => {
    try {
        let docData = await getDocWithError('items', wsId);
        return { msg: 'success', data: docData };
    } catch (error) {
        return { msg: 'error', error: error.message };
    }
};
