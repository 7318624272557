import { v1 as uuidv1 } from 'uuid';
import usePersistStore from '../persistStore';
import { showToast } from '../../../sharedCode/Utils/showToastWrapper';
import { scrollToGroupContainer } from '../../components/Groups';
import {
    getDeletedItemsData,
    updateDeletedItems,
    updateFullDeletedItem
} from '../../../platformFiles/platformMessages';

export const restoreItemFromBinAction = (get, set, deletedData, type) => {
    let category = usePersistStore.getState().selectedCategory;
    let tabGroups = get().tabGroups;
    let deletedItemsData = structuredClone(get().deletedItemsData);

    const currentCategoryID = get().categories[category].id;
    const currentSlug = get().categories[category].slug && {
        slug: get().categories[category].slug
    };
    const groupsInCategoryLength = tabGroups.filter(
        (tabs) => tabs.categoryID === currentCategoryID
    ).length;

    let filteredDeletedItems = deletedItemsData.deletedItems.filter(
        (e) => e.deletedData.id !== deletedData.id
    );
    let newId = uuidv1();
    const dateCreated = new Date().toString();

    if (type === 'group') {
        if (groupsInCategoryLength < 16) {
            deletedData['lastAdded'] = dateCreated;
            deletedData['categoryID'] = currentCategoryID;
            deletedData['id'] = newId;
            let oldTabs = [...tabGroups];
            let newTabs = [...oldTabs, deletedData];
            get().updateTabGroups(newTabs);
            showToast(
                'Group restored',
                deletedData.title +
                    ' restored into resotred items in current category',
                'success'
            );
            updateFullDeletedItem(filteredDeletedItems);
            deletedItemsData.deletedItems = filteredDeletedItems;
            set({ deletedItemsData: deletedItemsData });
        } else if (groupsInCategoryLength >= 16) {
            showToast(
                'Max groups reached',
                'Create a new category to create more groups',
                'warning'
            );
        }
    } else if (type === 'item') {
        let oldTabs = [...tabGroups];
        let restoredItemsIndex = null;
        oldTabs.find((tabGroup, index) => {
            if (
                tabGroup.title === 'Restored items' &&
                tabGroup.categoryID === currentCategoryID
            ) {
                restoredItemsIndex = index;
                return true;
            }
        });
        if (groupsInCategoryLength < 16 || restoredItemsIndex) {
            let newTabs;
            if (restoredItemsIndex !== null) {
                oldTabs[restoredItemsIndex].tabs.push(deletedData);
                newTabs = oldTabs;
            } else {
                newTabs = [
                    ...oldTabs,
                    {
                        id: newId,
                        title: 'Restored items',
                        emoji: '♻️',
                        comments: 0,
                        categoryID: currentCategoryID,
                        lastAdded: dateCreated,
                        focus: false,
                        tabs: [deletedData],
                        ...currentSlug
                    }
                ];
            }
            get().updateTabGroups(newTabs);
            updateFullDeletedItem(filteredDeletedItems);

            showToast(
                'Item restored',
                'Item restored into Restored items in current category',
                'success'
            );
            deletedItemsData.deletedItems = filteredDeletedItems;
            set({ deletedItemsData: deletedItemsData });
        } else if (groupsInCategoryLength >= 16 && !restoredItemsIndex) {
            showToast(
                'Max groups reached',
                'Create a new category to create more groups',
                'warning'
            );
        }
    }
};

export const removeItemFromBinAction = (get, set, idToDelete) => {
    let deletedItemsData = structuredClone(get().deletedItemsData);

    let filteredDeletedItems = deletedItemsData.deletedItems.filter(
        (e) => e.deletedData.id !== idToDelete
    );
    showToast('Item permanently removed', '', 'info');
    updateFullDeletedItem(filteredDeletedItems);
    deletedItemsData.deletedItems = filteredDeletedItems;
    set({ deletedItemsData: deletedItemsData });
};

export const fetchDeletedItemsDataAction = (set) => {
    getDeletedItemsData().then((response: any) => {
        if (response?.docData?.deletedItems) {
            set({ deletedItemsData: response.docData });
        } else {
            set({ deletedItemsData: { deletedItems: [] } });
        }
    });
};

export const saveDeletedDataAction = (deletedData, type) => {
    const dateDeleted = new Date().toString();
    let deletedDataExtended = [];
    deletedData.forEach((deletedDataItem) => {
        let tempDeletedDataObj = {};
        tempDeletedDataObj['deletedTimestamp'] = dateDeleted;
        tempDeletedDataObj['deletedType'] = type;
        tempDeletedDataObj['deletedData'] = deletedDataItem;
        deletedDataExtended.push(tempDeletedDataObj);
    });
    updateDeletedItems(deletedDataExtended);
};
