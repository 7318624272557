// import useStore from './store';

export const resetStatesAction = (set, get) => {
    set({
        // dragIds: [],
        globalIsDragging: false,
        globalGroupIsDragging: false,
        insertPosition: {},
        activeItemLayout: null,
        dragItemDisplacement: null,
        overZu: null,
        activeZu: null,
        overContainerZu: null,
        // blurOnDropIds: {},
        // globalIsDragging: false,
        // globalGroupIsDragging: false,
        dragItems: [],
        dragIds: [],
        activeItem: null,
        newDragEndId: {},
        dragEndAnimation: {},
        // isDropping: false,
        dragSingleFolder: false,
        // hideDragIds: {},
        dragItemsConfirmed: {}
    });
};

export const setBlurOnDropIdsAction = (set, newBlurOnDropIds) => {
    let newObject = {};
    newBlurOnDropIds.forEach((id) => {
        newObject[id] = true;
    });
    set({ blurOnDropIds: newObject });
};

export const setHideGridItemIdsAction = (set, newHideIds) => {
    let newObject = {};
    newHideIds.forEach((id) => {
        newObject[id] = true;
    });
    set({ hideGridItemIds: newObject });
};

export const setActiveItemByFetchAction = (
    set,
    containerIdSplit: { id: string; type: string; source: string },
    index
) => {};

export const setAnimationCompleteAction = (get, set) => {
    set({
        blurOnDropIds: {},
        globalIsDragging: false,
        globalGroupIsDragging: false,
        dragItems: [],
        dragIds: [],
        activeItem: null,
        newDragEndId: {},
        dragEndAnimation: {},
        isDropping: false,
        dragSingleFolder: false,
        // hideDragIds: {},
        dragItemsConfirmed: {}
    });

    let dragEndAnimationResolve = get().dragEndAnimationResolve;
    if (dragEndAnimationResolve) {
        let { resolve, cleanup } = dragEndAnimationResolve;
        cleanup?.();
        resolve();
    }
};

export const setNewDragEndIdAction = (get, set, oldId, newId) => {
    let newDragEndId = get().newDragEndId;
    newDragEndId[oldId] = newId;
    set({ newDragEndId: newDragEndId });
};

export const setGlobalIsDraggingAction = (get, set, newGlobalIsDragging) => {};
