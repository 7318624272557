import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ItemSize = 'small' | 'medium' | 'large';

interface PersistedTypes {
    selectedCategory: number;
    setSelectedCategory: (selectedCategory: number) => void;
    jumpNewTabState: boolean;
    toggleJumpNewTab: () => void;
    openNewTabState: boolean;
    toggleOpenNewTab: () => void;
    _hasHydrated: boolean;
    setHasHydrated: (value: boolean) => void;
    showTrashZone: boolean;
    toggleTrashZone: () => void;
    showURL: boolean;
    toggleShowURL: () => void;
    showWorkspaceEmoji: boolean;
    toggleWorkspaceEmoji: () => void;
    sidebarWidth: number;
    itemSize: string;
    shortcutCreateNewGroup: string;
    shortcutOpenWsGrid: string;
    shortcutOpenBin: string;
    shortcutSearch: string;
    stackShowLists: Record<string, boolean>;
    getStackShowList: (id: string) => boolean;
    setStackShowList: (id: string, value: boolean) => void;
    stackHideContent: Record<string, boolean>;
    getStackHideContent: (id: string) => boolean;
    setStackHideContent: (id: string, value: boolean) => void;
}
const initialState = {
    selectedCategory: 0,
    openNewTabState: false,
    jumpNewTabState: true,
    _hasHydrated: false,
    showTrashZone: true,
    showURL: true,
    showWorkspaceEmoji: false,
    sidebarWidth: 294,
    itemSize: 'large',
    shortcutCreateNewGroup: '',
    shortcutOpenWsGrid: '',
    shortcutOpenBin: '',
    shortcutSearch: '',
    stackShowLists: {},
    stackHideContent: {},
};

const usePersistStore = create<PersistedTypes>()(
    persist(
        (set, get) => ({
            ...initialState,
            getStackShowList: (id: string) => get().stackShowLists[id] ?? false,
            setStackShowList: (id: string, value: boolean) =>
                set((state) => ({
                    stackShowLists: {
                        ...state.stackShowLists,
                        [id]: value
                    }
                })),
            getStackHideContent: (id: string) => get().stackHideContent[id] ?? false,
            setStackHideContent: (id: string, value: boolean) =>
                set((state) => ({
                    stackHideContent: {
                        ...state.stackHideContent,
                        [id]: value
                    }
                })),
            setSelectedCategory: (selectedCategory) => {
                set({
                    selectedCategory
                });
            },
            toggleOpenNewTab: () =>
                set((prev) => ({ openNewTabState: !prev.openNewTabState })),
            toggleJumpNewTab: () =>
                set((prev) => ({ jumpNewTabState: !prev.jumpNewTabState })),
            toggleTrashZone: () =>
                set((prev) => ({
                    showTrashZone: !prev.showTrashZone
                })),
            toggleShowURL: () =>
                set((prev) => ({
                    showURL: !prev.showURL
                })),
            toggleWorkspaceEmoji: () =>
                set((prev) => ({
                    showWorkspaceEmoji: !prev.showWorkspaceEmoji
                })),
            setHasHydrated: (state) => {
                set({
                    _hasHydrated: state
                });
            },
            reset: () => set(initialState) // Update the initial state with the correct itemSize type
        }),
        {
            name: 'tabextend-settings', // name of item in the storage (must be unique)
            //partialize: (state) => ({ fishes: state.fishes }),
            onRehydrateStorage: () => (state) => {
                state.setHasHydrated(true);
            }
        }
    )
);

// const usePersistStore = create(
//     persist(
//         (set, get) => ({
//             productTourStep: 0,
//             nextStep: (value) => set((value) => ({ productTourStep: value })),
//             fxState: false,
//             toggleFxState: () => set((prev) => ({ fxState: !prev.fxState })),
//             showWorkspaceEmoji: false,
//             toggleWorkspaceEmoji: () =>
//                 set((prev) => ({
//                     showWorkspaceEmoji: !prev.showWorkspaceEmoji
//                 })),
//             showTrashZone: true,
//             toggleTrashZone: () =>
//                 set((prev) => ({
//                     showTrashZone: !prev.showTrashZone
//                 })),
//             openGroups: [],
//             setOpenGroups: () => set((value) => ({ openGroups: value })),
//             openNewTabState: false,
//             toggleOpenNewTab: () =>
//                 set((prev) => ({ openNewTabState: !prev.openNewTabState })),
//             jumpNewTabState: true,
//             toggleJumpNewTab: () =>
//                 set((prev) => ({ jumpNewTabState: !prev.jumpNewTabState })),
//             categorySelected: 0,
//             setCategorySelected: (value) =>
//                 set((value) => ({ categorySelected: value })),
//             shortcutCreateNewGroup: '',
//             setShortcutCreateNewGroup: (value) =>
//                 set(() => ({ shortcutCreateNewGroup: value })),
//             shortcutOpenWorkspacePicker: '',
//             shortcutOpenBin: '',
//             setShortcutOpenBin: (value) =>
//                 set(() => ({ shortcutOpenBin: value })),
//             setShortcutOpenWorkspacePicker: (value) =>
//                 set(() => ({ shortcutOpenWorkspacePicker: value })),
//             hoverSide: false,
//             pinSidebar: false,
//             togglePinSidebar: () =>
//                 set((prev) => ({ pinSidebar: !prev.pinSidebar })),
//             hoverSideOn: () => set(() => ({ hoverSide: true })),
//             hoverSideOff: () => {
//                 hoverOffAction(get, set);
//             }
//         }),
//         {
//             name: 'tabextend-settings' // unique name
//         }
//     )
// );

export default usePersistStore;
