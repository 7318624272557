import { create } from 'zustand';
import {
    clearSelectionAction,
    handleSelectAction
} from './actions/selectActions';
import layoutStore from './layoutStore';
import useStore from './store';
import usePersistStore from './persistStore';
import { changeWorkspaceFront } from '../../platformFiles/platformMessages';

type Highlight = { WSid: string; cat: number };

type Store = {
    loadingWsData: Highlight | null;
    setloadingWsData: (wsToChangeTo: Highlight) => void;
    changeWorkspace: (WSid: string, categoryIndex: number) => void;
    highlighted: Highlight | null;
    setHighlight: (highlightInfo: Highlight) => void;
    moveHighlight: (direction: 'up' | 'down' | 'left' | 'right') => void;
    newWsLoading: boolean;
};

const useWsGridStore = create<Store>(
    (set, get): Store => ({
        loadingWsData: null,
        setloadingWsData: (wsToChangeTo: Highlight) => {
            set({ loadingWsData: wsToChangeTo });
        },
        changeWorkspace: (WSid: string, categoryIndex: number) => {
            if (get().loadingWsData) return;
            get().setloadingWsData({ WSid: WSid, cat: categoryIndex });
            const currentWsId = useStore.getState().currentWsId;
            if (WSid === currentWsId) {
                usePersistStore.getState().setSelectedCategory(categoryIndex);
                layoutStore.getState().setWorkspaceZoom('zoomed-in');
            } else {
                changeWorkspaceFront(
                    get().setloadingWsData,
                    WSid,
                    categoryIndex
                );
            }
        },
        highlighted: null,
        setHighlight: (highlightInfo: Highlight) => {
            set({ highlighted: highlightInfo });
        },
        moveHighlight: (direction: 'up' | 'down' | 'left' | 'right') => {
            let currentHighlight;
            if (!get().highlighted) {
                let selectedCategory =
                    usePersistStore.getState().selectedCategory;
                const currentWsId = useStore.getState().currentWsId;
                currentHighlight = {
                    WSid: currentWsId,
                    cat: selectedCategory
                };
            } else {
                currentHighlight = get().highlighted;
            }
            const userData = useStore.getState().userData;
            const workspaces = userData?.workspaces;
            //case if up, change workspace id to -1 in index, same category if possible, otherwise last category in new workspace arr
            if (direction === 'up') {
                let currentWsIndex = workspaces.findIndex(
                    (ws) => ws.id === currentHighlight.WSid
                );
                // If the highlighted workspace is the first in the array, return early
                if (currentWsIndex === 0) {
                    return;
                }
                let newWsIndex = currentWsIndex - 1;
                if (newWsIndex < 0) {
                    newWsIndex = workspaces.length - 1;
                }
                let newWsId = workspaces[newWsIndex].id;
                let newWs = workspaces[newWsIndex];
                // Check if the new workspace has fewer categories than the current highlight
                let newCat = currentHighlight.cat;
                if (newWs.catData.length <= currentHighlight.cat) {
                    // If it does, set cat to the last index in the new workspace's category array
                    newCat = newWs.catData.length - 1;
                }

                set({
                    highlighted: {
                        WSid: newWsId,
                        cat: newCat
                    }
                });
            } else if (direction === 'down') {
                let currentWsIndex = workspaces.findIndex(
                    (ws) => ws.id === currentHighlight.WSid
                );
                // If the highlighted workspace is the last in the array, return early
                if (currentWsIndex === workspaces.length - 1) {
                    return;
                }
                let newWsIndex = currentWsIndex + 1;
                if (newWsIndex >= workspaces.length) {
                    newWsIndex = 0;
                }
                let newWsId = workspaces[newWsIndex].id;
                let newWs = workspaces[newWsIndex];
                // Check if the new workspace has fewer categories than the current highlight
                let newCat = currentHighlight.cat;
                if (newWs.catData.length <= currentHighlight.cat) {
                    // If it does, set cat to the last index in the new workspace's category array
                    newCat = newWs.catData.length - 1;
                }

                set({
                    highlighted: {
                        WSid: newWsId,
                        cat: newCat
                    }
                });
            }
            //case if left, change category id to -1 in index, same workspace if possible, otherwise last workspace in new category arr
            else if (direction === 'left') {
                let newCat = currentHighlight.cat - 1;
                let wsIndex = workspaces.findIndex(
                    (ws) => ws.id === currentHighlight.WSid
                );
                let ws = workspaces[wsIndex];
                if (newCat < 0) {
                    wsIndex--;
                    if (wsIndex < 0) {
                        wsIndex = workspaces.length - 1;
                    }
                    ws = workspaces[wsIndex];
                    newCat = ws.catData.length - 1;
                }
                set({
                    highlighted: {
                        WSid: ws.id,
                        cat: newCat
                    }
                });
            }
            //case if right, change category id to +1 in index, same workspace if possible, otherwise first workspace in new category arr
            else if (direction === 'right') {
                let newCat = currentHighlight.cat + 1;
                let wsIndex = workspaces.findIndex(
                    (ws) => ws.id === currentHighlight.WSid
                );
                let ws = workspaces[wsIndex];
                if (newCat >= ws.catData.length) {
                    newCat = 0;
                    wsIndex++;
                    if (wsIndex >= workspaces.length) {
                        wsIndex = 0;
                    }
                    ws = workspaces[wsIndex];
                }
                set({
                    highlighted: {
                        WSid: ws.id,
                        cat: newCat
                    }
                });
            }
        },
        newWsLoading: false
    })
);

export default useWsGridStore;

/* const setSelectionIdsAction = (set, newSelectedIds: string[]) => {
    set({
        selectedIds: newSelectedIds
    });
    layoutStore.setState({ currentDock: 'selectionBar' });
}; */
