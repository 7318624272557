export const clearLocalStorage = () => {};

export async function getFromLocal(key) {}

export async function getTotalBytesInUse() {}

export const getStorageValue = (key) => {
    return new Promise((resolve, reject) => {
        try {
            const value = window.localStorage.getItem(key);
            if (value === null) {
                // This means the key doesn't exist in localStorage
                // Resolve with null instead of rejecting the promise
                resolve(null);
            } else {
                // Only parse if the value looks like JSON (starts with '{' or '[')
                const firstChar = value.charAt(0);
                if (firstChar === '{' || firstChar === '[') {
                    resolve(JSON.parse(value));
                } else {
                    resolve(value);
                }
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const setStorageValue = (key, value) => {
    return new Promise((resolve, reject) => {
        try {
            const storedValue =
                typeof value === 'string' ? value : JSON.stringify(value);
            window.localStorage.setItem(key, storedValue);
            resolve(true);
        } catch (error) {
            reject(error);
        }
    });
};
