export const isTab = (item) => {
    return item.url?.length && !item.comment?.length;
};

export const isNote = (item) => {
    return !item.url?.length && item.comment?.length;
};

export const isTabNote = (item) => {
    return item.url?.length && item.comment?.length;
};

export const getItemType = (item) => {
    if (item.isStacked) {
        return 'stackedItem';
    } else if (isTab(item)) {
        return 'tabItem';
    } else if (isNote(item)) {
        return 'noteItem';
    } else if (isTabNote(item)) {
        return 'tabNoteItem';
    } else {
        return null;
    }
};
