import { fetchAndVerify } from './fetchMiddle';

export const favIconFetchGoogle = (url) => {
    let domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
};

export const favIconFetchAsync = async (url) => {
    return await refreshFavIconFetch(url, null);
};

const removeSubdomain = (domain) => {
    const parts = domain.split('.');
    if (parts.length > 2) {
        // Remove the first part (subdomain) and return the rest of the domain
        return parts.slice(1).join('.');
    }
    return domain; // Return the domain as is if there are no subdomains
};

export const refreshFavIconFetch = async (url, originalFavIcon) => {
    try {
        let faviconUrl = new URL('/favicon.ico', url).href;
        // Check if the direct favicon URL is valid and not too long
        const response = await fetchAndVerify(faviconUrl);
        if (response && response.length <= 336) {
            return response;
        } else {
            // Fallback to Google API with original domain
            let domain = new URL(url).hostname;
            let googleApiUrl = `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
            let googleResponse = await fetchAndVerify(googleApiUrl);
            if (googleResponse) {
                return googleApiUrl;
            } else {
                // Try again with subdomain removed
                domain = removeSubdomain(domain);
                googleApiUrl = `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
                googleResponse = await fetchAndVerify(googleApiUrl);
                if (googleResponse) {
                    return googleApiUrl;
                } else {
                    // Revert to the original favicon URL if all attempts fail
                    return originalFavIcon;
                }
            }
        }
    } catch (error) {
        console.error('Error fetching favicon:', error);
        // Revert to the original favicon URL in case of any error
        return originalFavIcon;
    }
};

export const checkFavIconLength = (favIconUrl, siteUrl) => {
    if (favIconUrl && favIconUrl.length <= 336) {
        return favIconUrl;
    } else {
        return favIconFetchGoogle(siteUrl);
    }
};
