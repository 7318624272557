import { create } from 'zustand';
import {
    clearSelectionAction,
    handleSelectAction
} from './actions/selectActions';
import layoutStore from './layoutStore';

type Store = {
    selectedIds: string[];
    setSelectedIds: (newSelectedIds: string[]) => void;
    selectedContainer: string;
    modifierKey: any;
    setModifierKey: (modifierKey: any) => void;
    handleSelect: (
        id: string,
        event: any,
        containerId: string,
        type: string
        // data?: TabItem
    ) => void;
    clearSelection: () => void;
    selectedExtraInfo: any;
};

const useSelectStore = create<Store>(
    (set, get): Store => ({
        selectedIds: [],
        setSelectedIds: (newSelectedIds: string[]) => {
            setSelectionIdsAction(set, newSelectedIds);
        },
        selectedContainer: null,
        modifierKey: null,
        setModifierKey: (newModifierKey: string) => {
            set({ modifierKey: newModifierKey });
        },
        handleSelect: (
            id: string,
            event,
            containerId: string,
            type: string
            // data: TabItem
        ) => {
            handleSelectAction(set, get, id, event, containerId, type);
        },
        clearSelection: () => {
            clearSelectionAction(set, get);
        },
        selectedExtraInfo: { anySelectedStack: false }
    })
);

export default useSelectStore;

const setSelectionIdsAction = (set, newSelectedIds: string[]) => {
    set({
        selectedIds: newSelectedIds
    });
    layoutStore.setState({ currentDock: 'selectionBar' });
};
