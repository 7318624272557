import {
    highlightActiveTabs,
    highlightTabs
} from '../../../platformFiles/browserAPI';
import layoutStore from '../layoutStore';
import useStore from '../store';

export const handleSelectAction = (set, get, id, event, containerId, type) => {
    (document.activeElement as HTMLElement).blur();
    document.body.style.userSelect = 'none';
    document.body.style.webkitUserSelect = 'none';
    let selectedIds = get().selectedIds;
    let newSelectedIds = [...selectedIds];
    let selectedContainerRawId = get().selectedContainer;

    if (
        selectedContainerRawId === null ||
        selectedContainerRawId !== containerId
    ) {
        set({ selectedContainer: containerId });
        selectedIds = [];
    }
    if (type === 'activeGroup') {
        if (selectedIds[0] === id) {
            set({
                selectedContainer: null,
                selectedExtraInfo: { anySelectedStack: false }
            });
            newSelectedIds = [];
        } else {
            newSelectedIds = [id];
        }
    } else if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
        if (selectedIds[0] === id && selectedIds.length < 2) {
            newSelectedIds = [];
            set({
                selectedContainer: null,
                selectedExtraInfo: { anySelectedStack: false }
            });
        } else {
            newSelectedIds = [id];
        }
    } else if (selectedIds.length === 0) {
        newSelectedIds = [id];
    } else if (event.metaKey || event.ctrlKey) {
        if (selectedIds.includes(id)) {
            newSelectedIds = selectedIds.filter((value) => value !== id);
        } else if (!selectedIds.length) {
            newSelectedIds = [id];
        } else {
            newSelectedIds = selectedIds.concat(id);
        }

        if (type === 'active') {
            let items =
                useStore.getState().allActiveTabs[containerId].activeTabs;
            let groupIds = [];
            items.forEach((item) => {
                if (item.children) {
                    groupIds.push(item.id);
                }
            });
            newSelectedIds = newSelectedIds.filter(
                (id) => !groupIds.includes(id)
            );
            //highlightActiveTabs(newSelectedIds, containerIdSplit.id);
        }
    } else if (event.shiftKey) {
        let items;
        if (type === 'active') {
            items = useStore.getState().allActiveTabs[containerId].activeTabs;
            let groupIds = [];
            items.forEach((item) => {
                if (item.children) {
                    groupIds.push(item.id);
                }
            });
            newSelectedIds = selectRange(items, selectedIds, id);
            newSelectedIds = newSelectedIds.filter(
                (id) => !groupIds.includes(id)
            );
        } else {
            items = useStore
                .getState()
                .tabGroups.find((group) => group.id === containerId)?.tabs;
            newSelectedIds = selectRange(items, selectedIds, id);
        }
    }
    set({ selectedIds: newSelectedIds });
    if (type === 'active' /* || type === 'activeGroup' */) {
        console.log(
            'newSelectedIds: ',
            newSelectedIds,
            'containerId: ',
            containerId
        );
        highlightActiveTabs(newSelectedIds, containerId);
    }
    if (type !== 'active' && type !== 'activeGroup') {
        let items = useStore
            .getState()
            .tabGroups.find((group) => group.id === containerId)?.tabs;
        let anySelectedStack = false;
        items.forEach((item) => {
            if (newSelectedIds.includes(item.id) && item.isStacked) {
                anySelectedStack = true;
            }
        });
        set({ selectedExtraInfo: { anySelectedStack: anySelectedStack } });

        layoutStore.setState({ currentDock: 'selectionBar' });
    }
};

const selectRange = (arr, selectedIds, newIdToSelect) => {
    let lastSelected = selectedIds[selectedIds.length - 1];
    let lastSelectedIndex = arr.findIndex((item) => item.id === lastSelected);
    let newSelectedIndex = arr.findIndex((item) => item.id === newIdToSelect);
    let isSelectingForwards = newSelectedIndex > lastSelectedIndex;
    let start = isSelectingForwards ? lastSelectedIndex : newSelectedIndex;
    let end = isSelectingForwards ? newSelectedIndex : lastSelectedIndex;
    let newSelectedIds = [...selectedIds];
    arr.forEach((item, index) => {
        if (index >= start && index <= end) {
            if (!selectedIds.includes(item.id)) {
                newSelectedIds.push(item.id);
            }
        }
    });
    return newSelectedIds;
};

export const clearSelectionAction = (set, get) => {
    let selectedIds = get().selectedIds;
    if (selectedIds.length !== 0) {
        set({
            selectedIds: [],
            selectedExtraInfo: { anySelectedStack: false },
            selectedContainer: null
        });
        highlightTabs([]);
        layoutStore.setState({ currentDock: null });
    }
};
